import styled from "styled-components";

import Avatar from "../Avatar";
import {Column} from "../Flex";
import {P} from "../Text";

export const Circle = styled(Avatar).attrs({
  size: "small",
})`
  flex-shrink: 0;
  font-size: 16px;
  margin-right: 12px;
`;

export const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.divider02};
  border-top: 1px solid ${({theme}) => theme.divider02};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: 72px;
  max-width: 100%;
  min-width: 0;
  padding: 15px 20px;
  user-select: none;
`;

export const Info = styled(P)`
  color: ${({theme}) => theme.white};
  flex: 1;
  font-size: 13px;
  min-width: 0;
  opacity: 0.7;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.2s;
  white-space: nowrap;
`;

export const InfoContainer = styled(Column)`
  justify-content: center;
  width: calc(100% - 45px);
  transition: opacity 0.2s;
`;

export const Name = styled(P)`
  color: ${({theme}) => theme.white};
  flex: 1;
  font-size: 17px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.2s;
  white-space: nowrap;
`;
