import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {textAlignProperty} from "../../helpers";
import P from "../P";

const PrimaryText = styled(P)`
  color: ${({theme}) => theme.textDark};
`;

PrimaryText.displayName = "PrimaryText";

PrimaryText.propTypes = {
  small: bool,
  light: bool,
  align: oneOf(textAlignProperty),
};

PrimaryText.defaultProps = {
  small: false,
  align: "left",
};

export default PrimaryText;
