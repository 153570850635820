import styled from "styled-components";

import {Row} from "../Flex";
import {PrimaryText} from "../Text";
import responsive from "../utils/responsive";

export const CoBrandingImage = styled.img`
  height: 100%;
  max-width: 100px;
  object-fit: contain;
  transition: opacity 0.2s;
  width: 100%;
`;

export const Container = styled(Row)`
  align-items: center;
  margin-bottom: 15px;
  margin-top: -10px;

  ${responsive.tablet} {
    padding-left: 6px;
  }
`;

export const InPartnership = styled(PrimaryText)`
  color: ${({theme}) => theme.white};
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-right: 5px;
  opacity: 0.7;
  text-transform: uppercase;
  transition: opacity 0.2s;
`;

export const ShortCoBrandingImage = styled.img`
  flex-shrink: 0;
  margin-left: 21px;
  transition: all 0.2s;

  ${responsive.laptop} {
    display: none;
  }
`;
