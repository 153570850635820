import styled from "styled-components";
import {Row} from "../Flex";

const getColorByType = (theme, type) => {
  const types = {
    default: {
      bg: theme.primaryAccentMedium,
      border: theme.primaryLighten,
    },
    warning: {
      bg: theme.warning01,
      border: theme.warning05,
    },
    error: {
      bg: theme.error01,
      border: theme.error05,
    },
  };

  return types[type] || types.default;
};

export const Wrapper = styled(Row)`
  border: 1px solid;
  padding: 11px 13px;
  width: 100%;
  margin-bottom: 1px;
  ${({theme, type}) => {
    const colors = getColorByType(theme, type);

    return `
      background-color: ${colors.bg};
      border-color: ${colors.border};
    `;
  }}
  & + & {
    margin-top: 4px;
  }
`;
