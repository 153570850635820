import React, {useEffect, useRef, useCallback, useState, useMemo} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {arrayOf, shape, string, func, bool, oneOf} from "prop-types";

import * as S from "./Tabs.style";

export const Tab = ({
  text,
  onTabClick,
  size,
  withFitBorder,
  startsWith,
  to,
  testID,
  onItemClick,
}) => {
  const {pathname} = useLocation();
  const tabRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isActive = useMemo(() => pathname.startsWith(startsWith), [pathname]);

  useEffect(
    () => {
      if (isActive) {
        onTabClick(tabRef.current);
        onItemClick();
      }
    },
    [isActive, onItemClick, onTabClick, pathname],
  );

  return (
    <S.TabArea
      active={isActive.toString()}
      onClick={() => {
        onTabClick(tabRef.current);
        onItemClick();
      }}
      ref={tabRef}
      withfitborder={withFitBorder.toString()}
      as={NavLink}
      to={to || startsWith}
      data-testid={testID}
    >
      <S.Tab size={size}>{text}</S.Tab>
    </S.TabArea>
  );
};

Tab.defaultProps = {
  onItemClick: () => {},
};

Tab.propTypes = {
  text: string.isRequired,
  onTabClick: func.isRequired,
  size: oneOf(["medium", "large", "small"]).isRequired,
  withFitBorder: bool.isRequired,
  startsWith: string.isRequired,
  to: string,
  testID: string,
  onItemClick: func,
};

const Tabs = ({items, style, className, size, withFitBorder}) => {
  const [position, setPosition] = useState({offsetLeft: 0, offsetWidth: 0});

  const onTabClick = useCallback(ref => {
    const {offsetLeft, offsetWidth} = ref;

    setPosition({offsetLeft, offsetWidth});
  }, []);

  return (
    <S.Wrapper style={style} className={className}>
      {items.map(elem => (
        <Tab
          key={elem.startsWith}
          {...elem}
          onTabClick={onTabClick}
          size={size}
          withFitBorder={withFitBorder}
        />
      ))}
      <S.Border {...position} />
    </S.Wrapper>
  );
};

Tabs.propTypes = {
  items: arrayOf(
    shape({
      text: string.isRequired,
      startsWith: string.isRequired,
    }),
  ).isRequired,
  style: shape({}),
  className: string,
  size: oneOf(["medium", "large", "small"]),
  withFitBorder: bool,
};

Tabs.defaultProps = {
  style: {},
  size: "large",
  withFitBorder: true,
};

export default Tabs;
