import React from "react";
import {string, number, func, bool, oneOf} from "prop-types";

import {Wrapper, BtnIcon, BtnText, LoadingIcon} from "./Button.style";

const Button = ({
  text,
  fontSize,
  textUnderline,
  icon,
  rightIcon,
  iconSize,
  onClick,
  disabled,
  loading,
  buttonType,
  type,
  testID,
  ...props
}) => (
  <Wrapper
    variant={type}
    onClick={onClick}
    disabled={disabled || loading}
    loading={loading}
    type={buttonType}
    data-testid={testID}
    {...props}
  >
    {loading && <LoadingIcon iconSize={iconSize} />}
    {icon && <BtnIcon name={icon} iconSize={iconSize} />}
    <BtnText fontSize={fontSize} underline={textUnderline}>
      {text}
    </BtnText>
    {rightIcon && <BtnIcon name={rightIcon} iconSize={iconSize} />}
  </Wrapper>
);

Button.propTypes = {
  square: bool,
  icon: string,
  rightIcon: string,
  type: oneOf(["primary", "secondary", "square", "tertiary", "error", "success", "black"]),
  buttonType: oneOf(["button", "submit"]),
  disabled: bool,
  narrow: bool,
  iconSize: number,
  fontSize: number,
  size: oneOf(["small", "medium", "big"]),
  text: string.isRequired,
  textUnderline: bool,
  loading: bool,
  onClick: func,
  testID: string,
};

Button.defaultProps = {
  type: "primary",
  buttonType: "button",
  disabled: false,
  narrow: false,
  icon: null,
  rightIcon: null,
  size: "big",
  iconSize: 18,
  fontSize: 18,
  textUnderline: false,
  loading: false,
  onClick: () => {},
  testID: "",
};

export default Button;
