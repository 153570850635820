import React from "react";
import {string} from "prop-types";

import {I} from "./Icon.style";

const Icon = ({name, className, ...rest}) => (
  <I className={`icon-${name} ${className}`} {...rest} />
);

Icon.propTypes = {
  name: string.isRequired,
  className: string,
};

export default Icon;
