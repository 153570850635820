import {useEffect} from "react";

const useClickOutside = (ref, callback, options = {stopEventOnClickOutside: false}) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
      if (options.stopEventOnClickOutside) {
        e.stopPropagation();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  });
};

export default useClickOutside;
