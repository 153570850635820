import styled, {css} from "styled-components";

import {withAlign, Heading, PrimaryText} from "../Text";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({column}) => (column ? "column" : "row")};
  align-items: ${({align = "stretch"}) => align};
  justify-content: ${({jc}) => jc || "flex-start"};
  width: ${({width}) => width || "auto"};
`;

export const EllipsisContainer = styled.div`
  flex-shrink: 1;
  min-width: 0;
  padding-right: 5px;
`;

export const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.divider06};
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
  margin-bottom: 8px;
`;

const ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 1;
`;

export const Label = styled(withAlign)`
  min-width: 120px;
  max-width: 120px;
  color: ${({theme}) => theme.textAccent};
  font-size: 12px;
  line-height: 1.5;
`;

export const Value = styled(withAlign)`
  color: ${({theme}) => theme.textDark};
  font-size: 12px;
  line-height: 1.5;
`;

export const Header = styled(Heading)`
  font-size: 17px;
  font-weight: 500;
  ${ellipsis}
`;

export const SubHeader = styled(PrimaryText).attrs(() => ({
  small: true,
}))`
  ${ellipsis}
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.divider};
  margin-top: ${({mt = 0}) => mt}px;
  margin-bottom: ${({mb = 0}) => mb}px;
`;
