import styled from "styled-components";

import responsive from "../utils/responsive";

export const Container = styled.div`
  align-items: center;
  color: ${({theme}) => theme.textDark};
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  height: 105px;
  width: 100px;

  ${responsive.laptop} {
    height: 75px;
  }

  ${responsive.mobileL} {
    height: 65px;
  }
`;

export const LogoImage = styled.img`
  max-width: 220px;
  object-fit: contain;
  transition: opacity 0.2s;

  ${responsive.laptop} {
    height: 45px;
    max-width: 195px;
  }

  ${responsive.mobileL} {
    display: none;
  }
`;

export const MobileLogoImage = styled(LogoImage)`
  display: none;

  ${responsive.mobileL} {
    display: inherit;
  }
`;

export const ShortLogoImage = styled.img`
  flex-shrink: 0;
  margin: 15px 0;
  object-fit: contain;
  transition: all 0.2s;
  width: 56px;
  ${responsive.laptop} {
    display: none;
  }
`;
