import React from "react";
import {node, oneOf, string} from "prop-types";

import {Wrapper} from "./Avatar.style";

const Avatar = ({children, firstName, formattedName, imageUrl, lastName, size, type, ...rest}) => (
  <Wrapper imageUrl={imageUrl} size={size} type={type} {...rest}>
    {!imageUrl && (children || formattedName || firstName.charAt(0) + lastName.charAt(0))}
  </Wrapper>
);

Avatar.propTypes = {
  children: node,
  firstName: string,
  formattedName: string,
  imageUrl: string,
  lastName: string,
  size: oneOf(["extra-large", "large", "big", "medium", "small"]),
  type: oneOf(["dark", "default", "light", "primary", "white"]),
};

Avatar.defaultProps = {
  children: null,
  firstName: "",
  formattedName: "",
  lastName: "",
  size: "medium",
  type: "default",
};

export default Avatar;
