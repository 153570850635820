import React from "react";
import {string, oneOf, bool} from "prop-types";

import {Wrapper, TagIcon} from "./Tag.style";

export const validTypes = ["default", "info", "success", "error"];

const Tag = ({icon, text, type, small}) => (
  <Wrapper type={validTypes.includes(type) ? type : validTypes[0]} small={small}>
    {icon && <TagIcon name={icon} />}
    {text}
  </Wrapper>
);

Tag.propTypes = {
  icon: string,
  text: string.isRequired,
  small: bool,
  type: oneOf(validTypes),
};

Tag.defaultProps = {
  icon: null,
  type: "default",
  small: false,
};

export default Tag;
