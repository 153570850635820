import styled, {keyframes} from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dashoffset: 187;
    transform: rotate(85deg);
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`;

const sizes = {
  big: 85,
  medium: 65,
  small: 45,
};

export const LoadWrap = styled.div`
  width: ${({size}) => sizes[size]}px;
  height: ${({size}) => sizes[size]}px;
  color: ${({color, theme}) => color || theme.primary};
  margin: auto;
`;

export const Svg = styled.svg`
  max-width: 100%;
  max-height: 100%;
  animation: ${spin} 1.4s linear infinite;
  padding: 10px;
`;

export const Circle = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
`;
