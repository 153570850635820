import React from "react";
import {string, shape, bool, func, oneOf, number} from "prop-types";

import {Wrapper, Input, AfterIcon, BeforeIcon} from "./SearchField.style";

const SearchField = ({
  input,
  placeholder,
  beforeIcon,
  disabled,
  afterIcon,
  size,
  type,
  inputmode,
  mt,
  mb,
  style,
  className,
  testID,
  beforeIconTestID,
  clearIconTestID,
}) => {
  const clearField = () => input.onChange("");

  return (
    <Wrapper mt={mt} mb={mb} style={style} className={className}>
      <Input
        data-testid={testID}
        type={type}
        inputmode={inputmode}
        placeholder={placeholder}
        disabled={disabled}
        {...input}
        size={size}
      />
      <BeforeIcon size={size} name={beforeIcon} data-testid={beforeIconTestID} />
      <AfterIcon size={size} name={afterIcon} onClick={clearField} data-testid={clearIconTestID} />
    </Wrapper>
  );
};

SearchField.propTypes = {
  input: shape({
    value: string,
    name: string.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  disabled: bool,
  placeholder: string,
  beforeIcon: string,
  afterIcon: string,
  size: oneOf(["small", "medium", "large"]),
  type: oneOf([
    "text",
    "email",
    "number",
    "password",
    "tel",
    "url",
    "month",
    "datetime-local",
    "date",
    "week",
    "time",
  ]),
  inputmode: oneOf(["none", "text", "decimal", "numeric", "tel", "search", "email", "url"]),
  mt: number,
  mb: number,
  style: shape({}),
  className: string,
  testID: string,
  beforeIconTestID: string,
  clearIconTestID: string,
};

SearchField.defaultProps = {
  disabled: false,
  placeholder: "",
  beforeIcon: "search",
  afterIcon: "circle-close",
  size: "medium",
  type: "text",
  inputmode: "search",
  mt: 25,
  mb: 0,
  className: "",
};

export default SearchField;
