import styled, {css} from "styled-components";

import getSize from "../utils/tabs";

import responsive from "../utils/responsive";
import {Heading} from "../Text";
import {Row} from "../Flex";

export const Wrapper = styled(Row)`
  align-items: stretch;
  position: relative;
  height: 70px;
  margin: 0 35px;
  margin-bottom: 26px;
  ${responsive.tablet} {
    margin: 0;
  }
`;

const withFitBorderStyles = css`
  margin: 0 20px;
  padding: 0;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-left: 0;
  }
`;

export const TabArea = styled(Row)`
  align-items: center;
  padding: 0 20px;
  ${({withfitborder}) => withfitborder === "true" && withFitBorderStyles}
  user-select: none;
  text-decoration: none;
  ${({active}) => active === "true" && `pointer-events: none;`}
  ${responsive.mobileL} {
    padding: 10px;
    ${({withfitborder}) => withfitborder === "true" && withFitBorderStyles}
    border-bottom-width: 3px;
    text-align: center;
  }
`;

export const Tab = styled(Heading).attrs({
  primary: "true",
  align: "center",
})`
  font-size: ${({size}) => getSize(size, [28, 18, 14])}px;
  font-weight: ${({size}) => getSize(size, [400, 400, 500])};
  &:active {
    color: ${({theme}) => theme.text};
  }
`;

export const Border = styled.div`
  position: absolute;
  bottom: 0;
  height: 5.1px;
  background-color: ${({theme}) => theme.primary};
  transition: left 0.3s ease-out, width 0.3s ease-out;
  will-change: left, width;
  left: ${({offsetLeft}) => offsetLeft}px;
  width: ${({offsetWidth}) => offsetWidth}px;
  ${responsive.mobileL} {
    height: 3px;
  }
`;
