import styled from "styled-components";

import Icon from "../Icon";
import {Row} from "../Flex";
import {MediumText, P} from "../Text";

export const IconComponent = styled(Icon)`
  color: ${({theme}) => theme.white};
  margin-right: 10px;
  font-size: 24px;
`;

export const Title = styled(MediumText)`
  font-size: 20px;
  font-weight: 500;
  color: ${({theme}) => theme.white};
`;

export const SubTitle = styled(P)`
  color: ${({theme}) => theme.white};
`;

export const Wrapper = styled(Row)`
  align-items: center;
  padding: 10px 0;
`;
