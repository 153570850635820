import styled from "styled-components";
import {RadioCheckboxWrapper} from "../utils/radioChackbox";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({horizontal}) => (horizontal ? "row" : "column")};
  justify-content: ${({horizontal}) => (horizontal ? "space-between" : "flex-start")};
  margin-top: ${({mt}) => mt}px;
  margin-bottom: ${({mb}) => mb}px;
  ${({horizontal}) =>
    horizontal &&
    `
    ${RadioCheckboxWrapper} {
      flex: 1;
      + ${RadioCheckboxWrapper} {
        margin-left: 20px;
      }
    }
  `}
`;
