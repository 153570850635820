import React, {useMemo} from "react";
import {bool, func, node, number, oneOf, shape, string} from "prop-types";

import Icon from "../Icon";
import {
  AfterWrapper,
  Bar,
  BeforeWrapper,
  Error,
  Input,
  Label,
  Textarea,
  Wrapper,
  ActionsWrapper,
} from "./TextField.style";

// label offset for multiline TextField
const labelOffset = 7;

const TextField = ({
  afterIcon,
  autoComplete,
  autoFocus,
  beforeIcon,
  className,
  disabled,
  input,
  inputmode,
  inputRef,
  label,
  maxLength,
  mb,
  meta,
  mt,
  multiline,
  placeholder,
  prefix,
  required,
  rows,
  stickyLabel,
  style,
  testID,
  type,
  onAfterIconClick,
  actionsFields,
  bgColor,
  borderValidColor,
  labelPT,
}) => {
  const sticky = placeholder.length > 2 || stickyLabel;
  const marginTop = useMemo(
    () => {
      const upMargin = mt || 0;

      if (multiline) {
        return upMargin + labelOffset;
      }

      return upMargin;
    },
    [mt, multiline],
  );
  const hasError = meta.touched && (meta.error || (meta.submitError && !meta.dirtySinceLastSubmit));
  const beforeContent = prefix.length > 0 || beforeIcon.length > 0;

  const fieldProps = {
    "data-testid": testID,
    placeholder,
    required,
    type,
    ...input,
    afterIcon,
    autoComplete,
    autoFocus,
    beforeContent,
    disabled,
    error: hasError,
    inputmode,
    maxLength,
    ref: inputRef,
    sticky,
    bgColor,
    borderValidColor,
    labelPT,
  };

  const textareaProps = {
    rows,
  };

  return (
    <Wrapper mt={marginTop} mb={mb} style={style} className={className}>
      {actionsFields && (
        <ActionsWrapper sticky={sticky} labelPT={labelPT}>
          {actionsFields}
        </ActionsWrapper>
      )}
      {multiline ? <Textarea {...fieldProps} {...textareaProps} /> : <Input {...fieldProps} />}
      {beforeContent && (
        <BeforeWrapper sticky={sticky}>{prefix || <Icon name={beforeIcon} />}</BeforeWrapper>
      )}
      <Bar />
      <Label error={hasError} sticky={sticky} labelPT={labelPT}>
        {label}
      </Label>
      {afterIcon && (
        <AfterWrapper isClickable={!!onAfterIconClick} onClick={onAfterIconClick}>
          <Icon name={afterIcon} />
        </AfterWrapper>
      )}
      {hasError && <Error>{meta.error || meta.submitError}</Error>}
    </Wrapper>
  );
};

TextField.propTypes = {
  afterIcon: string,
  afterActionIcon: string,
  actionsFields: node,
  onAfterIconClick: func,
  autoComplete: string,
  autoFocus: bool,
  beforeIcon: string,
  bgColor: string,
  borderValidColor: string,
  afterIconVisibleOnActive: bool,
  className: string,
  disabled: bool,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: string,
  }).isRequired,
  inputmode: oneOf(["decimal", "email", "none", "numeric", "search", "tel", "text", "url"]),
  inputRef: shape({
    current: shape({
      focus: func,
    }),
  }),
  label: string,
  maxLength: number,
  mb: number,
  meta: shape({
    error: string,
    submitError: string,
    touched: bool,
  }).isRequired,
  mt: number,
  multiline: bool,
  placeholder: string,
  prefix: string,
  required: bool,
  rows: number,
  stickyLabel: bool,
  style: shape({}),
  testID: string,
  type: oneOf([
    "date",
    "datetime-local",
    "email",
    "month",
    "number",
    "password",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]),
  labelPT: number,
};

TextField.defaultProps = {
  autoComplete: "on",
  autoFocus: false,
  beforeIcon: "",
  className: "",
  disabled: false,
  inputmode: "text",
  label: "",
  maxLength: 255,
  mb: 0,
  mt: 38,
  multiline: false,
  placeholder: " ",
  prefix: "",
  required: false,
  rows: 1,
  stickyLabel: false,
  style: {},
  testID: "",
  type: "text",
  labelPT: -5,
  onAfterIconClick: null,
};

TextField.displayName = "TextField";

export default TextField;
