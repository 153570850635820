import styled from "styled-components";

import {SubTitle as Text, P} from "../Text";
import Button from "../Button";
import {Row, Column} from "../Flex";
import TextField from "../TextField";
import responsive from "../utils/responsive";

export const Container = styled(Row)`
  display: inline-flex;
  background-color: ${({theme}) => theme.themeWhite};
  align-items: center;
  position: relative;
  max-width: 100%;
`;

export const Content = styled(Row)`
  max-width: calc(100% - 130px);
  margin-right: 130px;
  ${responsive.mobileL} {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
  & > * {
    display: flex;
    max-width: 100%;
    flex-flow: row wrap;
    margin-bottom: -5px;
  }
`;

export const SectionDropDown = styled(Column)`
  border: solid 1px ${({theme}) => theme.themeWhite};
  background-color: ${({theme}) => theme.themeWhite};
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
  min-width: 225px;
  max-width: 300px;
  z-index: 1;
  ${({theme}) => `box-shadow: 2px 2px 10px 0 rgba(${theme.blackShadow}, 0.12),
    0 1px 3px 0 rgba(${theme.blackShadow}, 0.1);`}
  padding: 18px 23px 25px;
  transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  ${({isVisible}) => `
    opacity: ${isVisible ? 1 : 0};
    visibility: ${isVisible ? "visible" : "hidden"};
    transform: translateY(${isVisible ? 0 : 30}px);
  `}
  cursor: auto;
`;

export const Section = styled.div`
  position: relative;
  max-width: 200px;
  min-width: 135px;
  padding: 5px 9px;
  cursor: pointer;
  @media screen and (max-width: 1400px) {
    max-width: 200px;
  }
  ${responsive.mobileL} {
    max-width: 100%;
    width: 50%;
    &:nth-child(even) {
      ${SectionDropDown} {
        left: auto;
        right: 0;
      }
    }
  }
`;

export const RowWrapper = styled(Row)`
  justify-content: space-between;
`;

export const ColumnWrapper = styled(Column)`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SectionTitle = styled(Text)`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: ${({theme}) => theme.textMedium};
`;

export const SectionSubTitle = styled(P).attrs({
  small: true,
})`
  grid-area: sub-title;
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px ${({theme}) => theme.divider06};
`;

export const SubTitle = styled(SectionSubTitle)`
  color: ${({theme}) => theme.primary};
`;

export const ClearAll = styled(Button).attrs({
  narrow: true,
  type: "secondary",
  size: "small",
  fontSize: 13,
  icon: "close",
})`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -16.5px;
  transition: opacity 0.2s, visibility 0.2s;

  ${responsive.mobileL} {
    top: auto;
    bottom: 0;
    left: 0;
    margin-top: auto;
    width: 100%;
  }

  i {
    margin: 0 0 0 -8px;
  }

  ${({isClearVisible}) => `
    opacity: ${isClearVisible ? 1 : 0};
    visibility: ${isClearVisible ? "visible" : "hidden"};
  `}

  &::before {
    content: "";
    display: flex;
    width: 1px;
    height: 40px;
    background: ${({theme}) => theme.divider};
    position: absolute;
    left: -17px;
    top: 50%;
    margin-top: -20px;
  }
`;

export const DropdownHead = styled(TextField).attrs(props => ({
  ...props,
  input: {
    ...props.input,
    style: {paddingRight: 28, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"},
  },
}))`
  position: relative;
  pointer-events: none;
  flex: 1;

  &::after {
    position: absolute;
    top: 21px;
    right: 10px;

    /* Styling the down arrow */
    width: 0;
    height: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${({theme}) => theme.textAccent};
    pointer-events: none;
  }
`;
