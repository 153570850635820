import React, {useMemo, memo} from "react";
import {string, shape, bool, func, arrayOf, number, oneOfType} from "prop-types";

import {Container, StyledSelect, Highlighter, Bar, Label, Item, Error} from "./Select.style";
import {addStarToLabelIfRequired} from "../utils/label";

const Select = ({
  items,
  placeholder,
  label,
  input,
  meta,
  disabled,
  required,
  stickyLabel,
  mt,
  mb,
  style,
  defaultValue,
  className,
  testID,
}) => {
  const sticky = placeholder.length > 1 || stickyLabel;
  const hasError = meta.error && meta.touched;
  const formattedLabel = useMemo(() => addStarToLabelIfRequired(label, required), [
    label,
    required,
  ]);

  return (
    <Container mt={mt} mb={mb} style={style} className={className}>
      <StyledSelect
        required
        {...input}
        error={hasError}
        disabled={disabled}
        defaultValue={defaultValue && ""}
        data-testid={testID}
      >
        {!!placeholder.length && (
          <Item disabled value="">
            {placeholder}
          </Item>
        )}
        {items.map(({value, label: text}) => (
          <Item key={value} value={value}>
            {text}
          </Item>
        ))}
      </StyledSelect>
      <Highlighter />
      <Bar error={hasError} />
      <Label error={hasError} sticky={sticky}>
        {formattedLabel}
      </Label>
      {hasError && <Error>{meta.error}</Error>}
    </Container>
  );
};

Select.propTypes = {
  items: arrayOf(
    shape({
      value: oneOfType([string, number]).isRequired,
      label: oneOfType([string, number]).isRequired,
    }),
  ).isRequired,
  label: string,
  input: shape({
    value: oneOfType([string, number]),
    name: string.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  placeholder: string,
  defaultValue: string, // not used in forms, used for simple selects
  disabled: bool,
  meta: shape({
    error: string,
    touched: bool.isRequired,
  }).isRequired,
  required: bool,
  stickyLabel: bool,
  mt: number,
  mb: number,
  style: shape({}),
  className: string,
  testID: string,
};

Select.defaultProps = {
  placeholder: " ",
  label: "",
  disabled: false,
  required: false,
  stickyLabel: false,
  mt: 36,
  mb: 0,
  style: {},
  className: "",
  testID: "",
};

export default memo(Select);
