import styled from "styled-components";

import responsive from "../utils/responsive";
import Icon from "../Icon";

export const TagIcon = styled(Icon)`
  font-size: 20px;
  line-height: 18px;
  margin-left: -5px;
  ${responsive.mobileL} {
    font-size: 17px;
    margin-left: -3px;
  }
`;

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  background-color: ${({type, theme}) => theme.statuses[type]};
  color: ${({theme}) => theme.white};
  font-size: 16px;
  min-width: 160px;
  padding: 0 15px;
  font-weight: 500;
  width: auto;
  user-select: none;
  ${({small}) =>
    small &&
    `
    min-width: 0;
    padding: 0 10px 0 8px;
    font-size: 13px;
    height: auto;
    min-height: 15px;
    line-height: 15px;
    ${TagIcon} {
      font-size: 15px;
      line-height: 15.5px;
      margin-left: -2px;
    }
  `}
  ${({small}) =>
    !small &&
    `
    ${responsive.mobileL} {
      min-width: 90px;
      height: 24px;
      line-height: 24px;
      font-size: 11px;
      padding: 0 10px;
    }`}
`;
