import React, {memo, useMemo} from "react";
import {arrayOf, bool, func, node, number, oneOfType, string} from "prop-types";

import IconButton from "../IconButton";
import {P} from "../Text";

import {defaultLabelDisplayedRows} from "../utils/table";
import {
  Body,
  BodyCell,
  Container,
  Gap,
  Head,
  HeadCell,
  HeadRow,
  NoDataContainer,
  NoDataTitle,
  OverflowWrapper,
  PaginationContainer,
  PaginationText,
  Row,
  SelectField,
  SortIcon,
  Wrapper,
} from "./Table.style";

const Cell = memo(({children, isSorted, isSortedDesc, head, ...rest}) => {
  if (head) {
    return (
      <HeadCell isSorted={isSorted} {...rest}>
        {isSorted && <SortIcon isASC={!isSortedDesc} />}
        {children}
      </HeadCell>
    );
  }

  return <BodyCell {...rest}>{children}</BodyCell>;
});

Cell.propTypes = {
  children: oneOfType([node, arrayOf(node)]),
  head: bool,
  isSorted: bool,
  isSortedDesc: bool,
};

Cell.defaultProps = {
  head: false,
  isSorted: false,
  isSortedDesc: false,
};

const Pagination = memo(
  ({
    labelRowsPerPage,
    nextPage,
    onPageSizeChange,
    pageIndex,
    pageSize,
    previousPage,
    rowsNumber,
    rowsPerPageOptions,
    setPageSize,
  }) => {
    const onChange = e => {
      const value = Number(e.target.value);
      setPageSize(value);
      if (onPageSizeChange) {
        onPageSizeChange(value);
      }
    };
    const labelDisplayedRows = useMemo(
      () =>
        defaultLabelDisplayedRows({
          from: rowsNumber === 0 ? 0 : pageIndex * pageSize + 1,
          to: Math.min(rowsNumber, (pageIndex + 1) * pageSize),
          count: rowsNumber,
        }),
      [rowsNumber, pageIndex, pageSize],
    );
    const selectProps = {
      input: {
        value: pageSize,
        onChange,
        name: "select",
      },
      items: rowsPerPageOptions.map(el => ({label: el, value: el})),
      meta: {
        error: undefined,
        touched: false,
      },
    };

    return (
      <PaginationContainer>
        <PaginationText mr={8}>{labelRowsPerPage}</PaginationText>
        <SelectField {...selectProps} />
        <PaginationText ml={60} mr={20}>
          {labelDisplayedRows}
        </PaginationText>
        <IconButton onClick={previousPage} icon="arrow-left" />
        <IconButton onClick={nextPage} icon="arrow-right" />
      </PaginationContainer>
    );
  },
);

Pagination.propTypes = {
  labelRowsPerPage: string,
  nextPage: func.isRequired,
  onPageSizeChange: func,
  pageIndex: number.isRequired,
  pageSize: number.isRequired,
  previousPage: func.isRequired,
  rowsNumber: number.isRequired,
  rowsPerPageOptions: arrayOf(number),
  setPageSize: func.isRequired,
};

Pagination.defaultProps = {
  labelRowsPerPage: "Rows per page:",
  rowsPerPageOptions: [10, 25, 50, 100],
};

const NoDataFallback = ({title, subTitle, titleTestID, subTitleTestID}) => (
  <NoDataContainer>
    <NoDataTitle data-testid={titleTestID}>{title}</NoDataTitle>
    <P primary data-testid={subTitleTestID}>
      {subTitle}
    </P>
  </NoDataContainer>
);

NoDataFallback.propTypes = {
  subTitle: string.isRequired,
  subTitleTestID: string,
  title: string.isRequired,
  titleTestID: string,
};

const TableContainer = ({children}) => (
  <OverflowWrapper>
    <Gap />
    <Container>{children}</Container>
    <Gap />
  </OverflowWrapper>
);

TableContainer.propTypes = {
  children: node.isRequired,
};

export default {
  Body,
  Cell,
  Container: TableContainer,
  Head,
  HeadRow,
  NoDataFallback,
  Pagination,
  Row,
  Wrapper,
};
