import styled from "styled-components";

import responsive from "../utils/responsive";
import {Row} from "../Flex";
import {Wrapper} from "../Button/Button.style";

export const HeadWrapper = styled(Row)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.divider04};
  padding: 24px 30px 24px 35px;
  min-height: 100px;
  ${responsive.laptop} {
    min-height: 70px;
  }
  ${responsive.tablet} {
    ${({hasSubHead}) => hasSubHead && "flex-flow: row wrap;"}
  }
  ${responsive.mobileL} {
    min-height: 60px;
    padding: 15px;
  }
`;

export const ChildrenWrapper = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
  margin-left: auto;
  ${Wrapper} {
    & + ${Wrapper} {
      margin-left: 5px;
    }
  }
`;

export const SubHeadComponent = styled.div`
  margin-right: auto;
  ${responsive.tablet} {
    order: 3;
    margin: 0;
    width: 100%;
  }
`;
