import React, {forwardRef} from "react";
import {func, oneOf, string} from "prop-types";

import {Circle, Container, Info, InfoContainer, Name} from "./PersonaCard.style";

const PersonaCard = forwardRef(({onClick, primaryInfo, secondaryInfo, testId, type}, ref) => {
  const splittedName = primaryInfo.split(" ");
  const isMultiWord = splittedName.length > 1;
  const shortName = isMultiWord
    ? splittedName[0].charAt(0) + splittedName[1].charAt(0)
    : splittedName[0].charAt(0);

  return (
    <Container data-testid={testId} onClick={onClick} ref={ref}>
      <Circle formattedName={shortName} type={type} />
      <InfoContainer>
        <Name>{primaryInfo}</Name>
        {secondaryInfo && <Info>{secondaryInfo}</Info>}
      </InfoContainer>
    </Container>
  );
});

PersonaCard.propTypes = {
  onClick: func.isRequired,
  primaryInfo: string.isRequired,
  secondaryInfo: string,
  testId: string,
  type: oneOf(["dark", "default", "light", "white"]),
};

PersonaCard.defaultProps = {
  secondaryInfo: "",
  testId: "",
  type: "dark",
};

export default PersonaCard;
