import styled from "styled-components";
import {Row, Column} from "../Flex";
import Icon from "../Icon";
import responsive from "../utils/responsive";
import {Caption, MediumText} from "../Text";

export const CollapseView = styled(Column)`
  overflow: hidden;
  transition: max-height 0.15s ease-in, opacity 0.15s;
  will-change: max-height;
`;

export const CollapseContent = styled(Column)`
  flex-shrink: 0;
`;

export const SectionTitleView = styled(Row)`
  justify-content: space-between;
  align-items: center;
  padding: 13px 0 10px;
  border-top: ${({theme, withTopBorder}) =>
    withTopBorder ? `1px solid ${theme.divider}` : "none"};
`;

export const SectionHeading = styled(MediumText)`
  flex: 1;
  line-height: 1.56;
  opacity: 0.85;
  padding-right: 10px;
`;

export const SectionInfo = styled(Row)`
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${responsive.mobileL} {
    flex-flow: column nowrap;
    align-items: flex-end;
  }

  & > * {
    &:not(:first-child) {
      margin-left: 10px;
      ${responsive.mobileL} {
        margin-left: 0;
      }
    }
  }
`;

export const ItemButton = styled(Column)`
  align-self: stretch;
  justify-content: center;
  border-right: 1px solid ${({theme}) => theme.divider06};
  color: ${({theme}) => theme.text};
  transition: background-color 0.2s, color 0.2s;
  user-select: none;
  width: 40px;
  font-size: 24px;
  line-height: 24px;
  ${responsive.laptop} {
    width: 33px;
  }
  ${responsive.mobileL} {
    width: 30px;
    font-size: 20px;
    line-height: 20px;
  }

  ${({clickable, theme}) =>
    clickable &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${theme.primaryAccentMedium};
        color: ${theme.primary};
      }
  `}
`;

export const ItemIcon = styled(Icon)`
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  align-self: center;
`;

export const ItemView = styled(Row)`
  border: 1px solid ${({theme}) => theme.divider06};
  background-color: ${({creamBackground, theme}) => (creamBackground ? theme.cream : theme.white)};
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 10px;
  contain: layout;
`;

export const ItemFooter = styled(Row)`
  border-top: 1px solid ${({theme}) => theme.divider04};
  background: ${({theme}) => theme.primaryAccentMedium};
  margin: 10px -35px -30px;
  padding: 12px 35px;
`;

export const ItemContent = styled(Column)`
  padding: 25px 35px 30px;
  flex: 1;
  ${responsive.laptop} {
    padding: 25px 30px 30px;
    ${ItemFooter} {
      margin: 10px -30px -30px;
      padding: 12px 30px;
    }
  }
  ${responsive.mobileL} {
    padding: 15px 15px 20px;
    ${ItemFooter} {
      margin: 10px -15px -20px;
      padding: 8px 15px;
    }
  }
`;

export const ItemTitle = styled(Row)`
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: ${({titlePb = 18}) => titlePb}px;
  user-select: none;
  cursor: ${({clickable}) => (clickable ? "pointer" : "unset")};
  border-bottom: ${({theme, withTitleBorder}) =>
    withTitleBorder ? `1px solid ${theme.divider}` : "none"};
  & + ${SectionTitleView} {
    border-top: none;
  }
  & + ${CollapseView} {
    ${({isOpened}) =>
      !isOpened &&
      `
        & + ${SectionTitleView} {
           border-top: none;
        }
    `}
  }
`;

export const ItemTitleRow = styled(Row)`
  align-items: baseline;
`;

export const TopImage = styled.div`
  pointer-events: none;
  margin-bottom: -15px;
  width: 100%;
  position: relative;
  z-index: 1;
  & > * {
    margin: 0 auto;
    padding: 0 6px;
    display: block;
    background: ${({theme}) => theme.themeWhite};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
`;

export const HeaderTitle = styled(Caption)`
  color: ${({theme}) => theme.grey};
  padding-bottom: 6px;
  padding-top: 6px;
  padding-right: 6px;
`;

export const HeaderExpand = styled.button`
  font-size: 14px;
  padding: 5px 0 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  color: ${({theme}) => theme.text};
  transition: color ease-out 0.2s;
  &:hover {
    color: ${({theme}) => theme.primaryDark};
  }
`;
