import styled from "styled-components";
import {oneOf} from "prop-types";
import {withAlign, textAlignProperty} from "../../helpers";
import responsive from "../../../utils/responsive";

const SubTitle = styled(withAlign)`
  color: ${({theme}) => theme.textMedium};
  font-size: 20px;
  line-height: 1.15;
  ${responsive.mobileL} {
    font-size: 15px;
  }
`;

SubTitle.displayName = "SubTitle";

SubTitle.propTypes = {
  align: oneOf(textAlignProperty),
};

SubTitle.defaultProps = {
  align: "left",
};

export default SubTitle;
