import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${({mt}) => mt}px;
  margin-bottom: ${({mb}) => mb}px;

  &::after {
    position: absolute;
    top: 21px;
    right: 10px;

    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid ${({theme}) => theme.textAccent};
    pointer-events: none;
  }
`;

export const Item = styled.option`
  font-family: inherit;
  color: ${({theme, placeholder}) => (placeholder ? theme.error : theme.textDark)};
  background-color: ${({theme}) => theme.themeWhite};
`;

export const StyledSelect = styled.select`
  position: relative;
  font-family: inherit;
  background-color: transparent;
  color: ${({theme}) => theme.textDark};
  width: 100%;
  padding: 12px 10px 12px 0;
  font-size: 16px;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid ${({theme}) => theme.primaryLighten};
  cursor: pointer;

  /* Use custom arrow */
  appearance: none;

  /* Remove focus */
  &:focus {
    outline: none;
    border-bottom: 2px solid transparent;
  }

  &:focus ~ label,
  &:valid ~ label {
    top: -11px;
    transition: 300ms ease all;
    font-size: 13px;
  }
  &:valid {
    border-bottom: 2px solid ${({theme}) => theme.textLight};
    color: ${({theme}) => theme.textDark};
  }

  &:focus ~ label {
    color: ${({theme, error}) => (error ? theme.error : theme.primary)};
  }

  &:focus ~ span[data-select="bar"]::before {
    width: 100%;
  }

  &:required:invalid {
    color: ${({theme}) => theme.textLight};
  }

  &:disabled {
    color: ${({theme}) => theme.textLight};
    cursor: initial;
  }

  ${({error, theme}) =>
    error &&
    `
    & ~ span:before {
      width: 100%;
      background-color: ${theme.error};
  }
  `}
`;

export const Highlighter = styled.span`
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
`;

export const Bar = styled.span.attrs({
  "data-select": "bar",
})`
  position: relative;
  display: block;
  width: 100%;

  &::before {
    content: "";
    height: 2.1px;
    width: 0;
    bottom: 0;
    position: absolute;
    background-color: ${({theme}) => theme.primary};
    transition: width 0.3s ease, background-color 0.3s;
    will-change: width;
    left: 0%;
  }
`;

export const Label = styled.label`
  color: ${({theme, error}) => (error ? theme.error : theme.textAccent)};
  font-size: ${({sticky}) => (sticky ? 13 : 16)}px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: ${({sticky}) => (sticky ? -11 : 14)}px;
  transition: 300ms ease all;
`;

export const Error = styled.span`
  position: absolute;
  color: ${({theme}) => theme.error};
  font-size: 13px;
  bottom: -15px;
`;
