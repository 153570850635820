import styled, {css, keyframes} from "styled-components";

import responsive from "../utils/responsive";
import getSizeByType from "../utils/searchField";
import Icon from "../Icon";

const autofill = ({theme}) => keyframes`
  to {
      color: ${theme.textDark};
      background: transparent;
  }
`;

export const Wrapper = styled.label.attrs(({name}) => ({
  htmlFor: name,
}))`
  position: relative;
  margin-top: ${({mt}) => mt}px;
  margin-bottom: ${({mb}) => mb}px;
  display: flex;
`;

export const Input = styled.input.attrs(({name}) => ({
  required: true,
  id: name,
}))`
  color: ${({theme}) => theme.textDark};
  width: 100%;
  font-family: ${({theme}) => theme.font};
  outline: none;
  border: 1px solid ${({theme}) => theme.primaryLight};
  background-color: ${({theme}) => theme.themeWhite};
  border-radius: 8px;
  padding: ${({size}) => getSizeByType(size, ["8px 33px", "14px 45px", "16px 50px"])};
  font-size: ${({size}) => getSizeByType(size, [14, 16, 16])}px;
  transition: width 100ms ease-in-out, background-color 0.2s, border-color 0.1s, box-shadow 0.1s;

  &::placeholder {
    color: ${({theme}) => theme.textAccent};
  }

  &:hover {
    @media not all and (pointer: coarse) {
      background-color: ${({theme}) => theme.primaryAccent};
    }
  }

  &:focus {
    background-color: ${({theme}) => theme.primaryAccent};
    border: 1px solid ${({theme}) => theme.primaryMedium};
    outline: 0;
    box-shadow: ${({theme}) => `2px 2px 10px 6px rgba(${theme.blackShadow}, 0.03)`};
  }

  /* Postfix */
  &:valid ~ i[data-select="postfix"] {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s ease;
  }

  ${responsive.mobileL} {
    padding: 8px 45px;
  }

  /* Removing input background colour for Chrome autocomplete */
  &:-webkit-autofill {
    animation-name: ${autofill};
    animation-fill-mode: both;
  }
`;

const iconStyles = css`
  font-size: ${({size}) => getSizeByType(size, [17, 21, 25])}px;
  position: absolute;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  cursor: pointer;

  ${responsive.mobileL} {
    font-size: 17px;
  }
`;

export const BeforeIcon = styled(Icon)`
  ${iconStyles}
  padding-left: 10px;
  color: ${({theme}) => theme.primary};
`;

export const AfterIcon = styled(Icon).attrs({
  "data-select": "postfix",
})`
  ${iconStyles}
  right: 0;
  padding-right: 10px;
  color: ${({theme}) => theme.textAccent};
  visibility: hidden;
  opacity: 0;
  transition: color 0.3s;
  &:hover {
    @media not all and (pointer: coarse) {
      color: ${({theme}) => theme.primary};
    }
  }
  &:active {
    transition-duration: 0s;
    color: ${({theme}) => theme.primary};
  }
`;
