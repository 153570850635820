import styled, {keyframes} from "styled-components";

import getSizeByType from "../utils/dialog";
import responsive from "../utils/responsive";
import {Heading} from "../Text";
import Icon from "../Icon";

const opacityFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Body = styled.div`
  align-items: center;
  animation: ${opacityFrames} 0.225s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  background-color: ${({theme}) => theme.themeWhite};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
  width: ${({size}) => getSizeByType(size, ["1038px", "900px", "650px", "556px"])};
  ${({side, customWidth, size}) =>
    side &&
    `
    width: ${(customWidth && `${customWidth}px`) ||
      getSizeByType(size, ["1038px", "900px", "650px", "556px"])};
    min-height: 100%;
    margin: ${side === "right" ? "0 0 0 auto" : "0 auto 0 0"};
  `};

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: ${({theme}) => `0 11px 15px -7px rgba(${
      theme.blackShadow
    }, 0.2), 0px 24px 38px 3px rgba(${theme.blackShadow}, 0.14),
      0px 9px 46px 8px rgba(${theme.blackShadow}, 0.12)`};
  }

  ${responsive.mobileL} {
    width: 100%;
    height: 100%;
    border-radius: 0;
    &::before {
      display: none;
    }
  }
`;

export const DialogHeader = styled.div`
  width: 100%;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({theme}) => theme.divider};
  position: relative;
  z-index: 1;
  ${responsive.mobileL} {
    min-height: 60px;
  }
`;

export const DialogTitle = styled(Heading)`
  flex-grow: 1;
  padding: 10px 50px;
`;

export const DialogClose = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  cursor: pointer;
  color: ${({theme}) => theme.text};
  transition: color 0.2s;
  width: 70px;
  &:hover {
    @media not all and (pointer: coarse) {
      color: ${({theme}) => theme.primary};
    }
    &:active {
      transition-duration: 0s;
      color: ${({theme}) => theme.textMedium};
    }
  }
  ${responsive.mobileL} {
    width: 55px;
  }
`;

export const DialogBack = styled(DialogClose)`
  left: 0;
`;

export const DialogCloseIcon = styled(Icon).attrs({
  name: "close",
})`
  font-size: 32px;
  color: currentColor;
  margin: auto;
  ${responsive.mobileL} {
    font-size: 22px;
  }
`;

export const DialogBackIcon = styled(DialogCloseIcon).attrs({
  name: "back",
})``;

export const DialogAction = styled.div`
  background-color: ${({theme}) => theme.themeWhite};
  border-radius: 5px;
  bottom: -50px;
  box-shadow: ${({theme}) => `0 -1px 3px 0 rgba(${theme.blackShadow}, 0.05)`};
  left: 0;
  padding: 15px;
  position: sticky;
  right: 0;
  width: 100%;
  z-index: 5;

  ${responsive.mobileL} {
    bottom: 0;
  }
`;

export const DialogContent = styled.div`
  padding: ${({withVP}) => (withVP ? "40px 15px 95px" : "0 15px")};
  width: 100%;
  max-width: ${({customMaxWidth, size}) =>
    (customMaxWidth && `${customMaxWidth}px`) ||
    getSizeByType(size, ["860px", "860px", "570px", "390px"])};
  flex-grow: 2;
  ${DialogAction} {
    position: absolute;
    bottom: 0;
  }
  ${responsive.mobileL} {
    overflow-y: auto;
    padding: ${({withVP}) => (withVP ? "15px 15px 90px" : "0 15px")};
  }
  &:not(:last-child) {
    padding-bottom: 35px;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  padding: ${({side}) => !side && "50px 0"};
  animation: ${opacityFrames} 0.225s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  background-color: ${({theme}) => theme.overlay};
  overflow: auto;
  ${responsive.mobileL} {
    padding: 0;
  }

  ${({withoutHeader}) =>
    withoutHeader &&
    `${DialogHeader} {
      border: none;
      height: 50px;
      min-height: 50px;
      align-items: flex-end;
      ${responsive.mobileL} {
        align-items: center;
      }
      ${DialogClose} {
        height: 70px;
      }
    }`}

  ${({compact, withVP}) =>
    compact &&
    `
    ${Body} {
      width: 420px;
      ${responsive.mobileL} {
        width: 100%;
      }
    }
    ${DialogHeader} {
      border: none;
      height: 50px;
      min-height: 50px;
      align-items: flex-end;
      ${responsive.mobileL} {
        align-items: center;
      }
    }
    ${DialogClose} {
      height: 70px;
      ${responsive.mobileL} {
        right: 0;
        height: 55px;
        margin-right: 0;
      }
    }
    ${DialogCloseIcon} {
      font-size: 24px;
    }
    ${DialogContent} {
      padding: 0 30px 30px;
       ${responsive.mobileL} {
        padding: ${withVP ? "0 15px 15px" : "0 15px"};
      }
    }
  `}
`;
