import React from "react";
import {node, string} from "prop-types";
import styled from "styled-components";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import {Column} from "../Flex";

import * as S from "./Toaster.style";

const Toaster = ({iconComponent, title, subTitle, iconName, ...rest}) => (
  <S.Wrapper {...rest}>
    {iconComponent}
    {iconName && <S.IconComponent name={iconName} />}
    <Column>
      {title && <S.Title>{title}</S.Title>}
      {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
    </Column>
  </S.Wrapper>
);

Toaster.propTypes = {
  title: string,
  subTitle: string,
  iconName: string,
  iconComponent: node,
};

Toaster.defaultProps = {
  title: "",
  subTitle: "",
  iconName: "",
  iconComponent: null,
};

const WrappedToastContainer = ({className, ...rest}) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

WrappedToastContainer.propTypes = {
  className: string,
};

const ToasterContainer = styled(WrappedToastContainer).attrs(props => ({
  position: props.position ?? toast.POSITION.TOP_RIGHT,
  hideProgressBar: props.hideProgressBar ?? true,
  closeButton: props.closeButton ?? false,
}))`
  .Toastify__toast-container {
    z-index: 1000000;
    min-width: 320px;
    width: auto;
  }
  .Toastify__toast {
    background-color: ${({theme}) => theme.primary};
    border-radius: 10px;
    padding: 0 10px;
    padding-right: 20px;
    min-height: auto;
  }
  .Toastify__toast--error {
    background-color: ${({theme}) => theme.error};
  }
  .Toastify__toast--warning {
    background-color: ${({theme}) => theme.warning};
  }
  .Toastify__toast--success {
    background-color: ${({theme}) => theme.success};
  }
`;

const toastConfig = {
  showSuccess: ({title = "", subTitle = "", iconName = "tick-circle"}) =>
    toast.success(<Toaster {...{title, subTitle, iconName}} />),
  showInfo: ({title = "", subTitle = "", iconName = "notification"}) =>
    toast.info(<Toaster {...{title, subTitle, iconName}} />),
  showWarn: ({title = "", subTitle = "", iconName = "warning"}) =>
    toast.warn(<Toaster {...{title, subTitle, iconName}} />),
  showError: ({title = "", subTitle = "", iconName = "error"}) =>
    toast.error(<Toaster {...{title, subTitle, iconName}} />),
};

export default {Item: Toaster, Container: ToasterContainer, toastConfig, toast};
