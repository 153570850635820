import React, {useCallback, useState} from "react";
import {bool, func, node, shape, string} from "prop-types";

import Icon from "../../Icon";
import {Header, HeaderExpand, HeaderTitle} from "../RPAccordion.style";
import {GlobalContext} from "./Context";

export const Wrapper = ({
  children,
  expandTestId,
  onToggle,
  shouldExpandAll,
  title,
  toggleText,
  testID,
}) => {
  const [isAllOpened, setAllOpened] = useState(false);

  const toggleOpen = useCallback(
    () => {
      setAllOpened(!isAllOpened);

      if (onToggle) {
        onToggle(!isAllOpened);
      }
    },
    [isAllOpened, onToggle],
  );

  return (
    <>
      <Header data-testid={testID}>
        <HeaderTitle>{title}</HeaderTitle>
        {shouldExpandAll && (
          <HeaderExpand onClick={toggleOpen} data-testid={expandTestId}>
            {toggleText[isAllOpened ? "close" : "open"]}
            <Icon name={isAllOpened ? "minus" : "plus"} />
          </HeaderExpand>
        )}
      </Header>
      <GlobalContext.Provider value={{isAllOpened}}>{children}</GlobalContext.Provider>
    </>
  );
};

Wrapper.propTypes = {
  children: node.isRequired,
  expandTestId: string,
  onToggle: func,
  shouldExpandAll: bool,
  title: string.isRequired,
  testID: string,
  toggleText: shape({
    close: string,
    open: string,
  }),
};

Wrapper.defaultProps = {
  expandTestId: "",
  onToggle: null,
  shouldExpandAll: true,
  testID: "",
  toggleText: {
    close: "Close All",
    open: "Show All",
  },
};
