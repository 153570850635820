import React from "react";
import {string} from "prop-types";

import {Container, LogoImage, ShortLogoImage, MobileLogoImage} from "./CanopyLogo.style";

const CanopyLogo = ({logoSrc, mobileLogoSrc, shortLogoSrc}) => (
  <Container>
    <ShortLogoImage src={shortLogoSrc} />
    <MobileLogoImage src={mobileLogoSrc || logoSrc} />
    <LogoImage src={logoSrc} />
  </Container>
);

CanopyLogo.propTypes = {
  logoSrc: string.isRequired,
  mobileLogoSrc: string,
  shortLogoSrc: string.isRequired,
};

CanopyLogo.defaultProps = {
  mobileLogoSrc: "",
};

export default CanopyLogo;
