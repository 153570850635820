import React from "react";
import {bool, oneOf, string} from "prop-types";

import {Container, NavIcon, NavText} from "./NavItem.style";

const NavItem = ({icon, iconActive, isActive, isDisabled, text, variant, ...rest}) => (
  <Container isDisabled={isDisabled} variant={variant} {...rest}>
    <NavIcon name={isActive ? iconActive : icon} />
    <NavText>{text}</NavText>
  </Container>
);

NavItem.propTypes = {
  className: string,
  icon: string.isRequired,
  iconActive: string.isRequired,
  isActive: bool,
  isDisabled: bool,
  text: string.isRequired,
  variant: oneOf(["admin", "hq"]),
};

NavItem.defaultProps = {
  isActive: false,
  isDisabled: false,
  variant: "hq",
};

export {NavText, Container as NavItemContainer};

export default NavItem;
