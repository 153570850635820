import * as label from "./label";
import responsive, {size} from "./responsive";
import * as scrollLock from "./scrollLock";
import * as baseLayoutConstants from "./baseLayoutConstants";

export default {
  label,
  responsive,
  size,
  scrollLock,
  baseLayoutConstants,
};
