import React from "react";
import {bool, func, shape, string} from "prop-types";

import {Container, LeftText, RightText, StyledIcon} from "./IconButton.style";

const IconButton = ({
  hideTextOnMobile,
  icon,
  isDisabled,
  isLoading,
  onClick,
  rightText, // FIXME: this is not needed here
  style,
  text, // FIXME: this is not needed here
  ...rest // FIXME: never destructure rest unless it is REALLY needed
}) => (
  <Container
    {...rest}
    disabled={isDisabled || isLoading}
    hideTextOnMobile={hideTextOnMobile}
    onClick={onClick}
    style={style}
  >
    {text && <LeftText>{text}</LeftText>}
    <StyledIcon name={icon} />
    {rightText && <RightText>{rightText}</RightText>}
  </Container>
);

IconButton.propTypes = {
  hideTextOnMobile: bool,
  icon: string.isRequired,
  isDisabled: bool,
  isLoading: bool,
  onClick: func,
  rightText: string,
  style: shape({}),
  text: string,
};

IconButton.defaultProps = {
  hideTextOnMobile: false,
  isDisabled: false,
  isLoading: false,
  onClick: () => {},
  rightText: "",
  style: {},
  text: "",
};

export default IconButton;
