import React from "react";
import {arrayOf, node, oneOfType, oneOf} from "prop-types";
import {Wrapper} from "./Detail.style";

const Detail = ({type, children, ...rest}) => (
  <Wrapper type={type} {...rest}>
    {children}
  </Wrapper>
);

Detail.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  type: oneOf(["default", "warning", "error"]),
};

Detail.defaultProps = {
  type: "default",
};

export default Detail;
