import styled from "styled-components";

import Icon from "../Icon";
import responsive from "../utils/responsive";
import {Caption} from "../Text";

export const StyledIcon = styled(Icon)`
  color: ${({theme, type}) => theme.statuses[type]};
  font-size: 20px;
  margin-right: 3px;
  &::before {
    margin: 0;
  }
  ${responsive.laptop} {
    margin-right: 0;
    margin-bottom: 2px;
  }
`;

export const Text = styled(Caption).attrs(() => ({
  align: "center",
  mobileSmall: true,
}))`
  color: ${({theme, type}) => theme.statuses[type]};
`;

export const Wrapper = styled.div`
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  ${({mobileWrap}) =>
    mobileWrap &&
    `
    ${responsive.laptop} {
    flex-flow: column nowrap;
    display: flex;
  }
  `}
`;
