import React from "react";
import {string, oneOf, shape, bool} from "prop-types";

import {Wrapper, StyledIcon, Text} from "./StatusText.style";

const StatusText = ({iconName, text, type, mobileWrap, style, testID}) => (
  <Wrapper style={style} mobileWrap={mobileWrap}>
    {iconName && <StyledIcon name={iconName} type={type} />}
    <Text type={type} data-testid={testID}>
      {text}
    </Text>
  </Wrapper>
);

StatusText.propTypes = {
  iconName: string,
  mobileWrap: bool,
  text: string.isRequired,
  testID: string,
  type: oneOf(["error", "info", "warning", "success", "default"]),
  style: shape({}),
};

StatusText.defaultProps = {
  type: "info",
  mobileWrap: true,
};

export default StatusText;
