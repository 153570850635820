import styled from "styled-components";

import {PrimaryText} from "../Text";

export const I = styled.i`
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: ${({theme}) => theme.primaryLightMedium};
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: ${({theme}) => theme.primaryLightMedium};
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: ${({theme}) => theme.white};
    border-radius: 11px;
    box-shadow: ${({theme}) => `0 2px 2px rgba(${theme.blackShadow}, 0.24)`};
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
`;

export const Input = styled.input.attrs({
  type: "checkbox",
})`
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;

  &:checked + i {
    background-color: ${({theme}) => theme.primary};
  }

  &:checked + i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }

  &:checked + i::after {
    transform: translate3d(22px, 2px, 0);
  }

  /* Disabled */
  &:disabled {
    opacity: 0;
  }

  &:disabled + i {
    cursor: initial;
    color: ${({theme}) => theme.primaryLightMedium};
  }

  &:disabled + i + span {
    color: ${({theme}) => theme.primaryLightMedium};
  }

  &:disabled + i::before {
    background-color: currentColor;
  }

  &:checked:disabled + i::after,
  &:indeterminate:disabled + i::after {
    border-color: transparent;
    background-color: currentColor;
  }
`;

export const Label = styled.label`
  cursor: ${({disabled}) => (disabled ? "initial" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: ${({mt}) => mt}px;
  margin-bottom: ${({mb}) => mb}px;

  &:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }

  &:active input:checked + i::after {
    transform: translate3d(16px, 2px, 0);
  }
`;

export const Span = styled(PrimaryText).attrs({
  as: "span",
  primary: true,
})``;
