export const size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 600,
  tablet: 768,
  laptop: 1024,
  desktop: 1440,
};

export default Object.keys(size).reduce((acc, cur) => {
  acc[cur] = `@media (max-width: ${size[cur]}px)`;

  return acc;
}, {});
