import styled from "styled-components";

import responsive from "../utils/responsive";
import Icon from "../Icon";
import {Heading} from "../Text";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 556px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 6px rgba(${({theme}) => theme.blackShadow}, 0.03);
  border: 1px solid ${({theme}) => theme.divider04};
  background-color: ${({theme}) => theme.themeWhite};
  box-sizing: border-box;
  ${responsive.mobileL} {
    border-radius: 0;
    flex-grow: 1;
    border-bottom: none;
  }
`;

export const Header = styled.div`
  min-height: 90px;
  display: flex;
  border-bottom: 1px solid ${({theme}) => theme.divider04};
  align-items: center;
  font-size: 24px;
  justify-content: center;
  box-sizing: border-box;
  ${responsive.mobileL} {
    min-height: 60px;
  }
`;

export const HeaderButton = styled.button`
  min-width: 70px;
  height: 70px;
  background-color: ${({theme}) => theme.transparent};
  border: none;
  cursor: pointer;
  color: ${({theme}) => theme.text};
  outline: none;
  &:hover {
    @media not all and (pointer: coarse) {
      color: ${({theme}) => theme.primary};
    }
    &:active {
      transition-duration: 0s;
      color: ${({theme}) => theme.textMedium};
    }
  }
  ${responsive.mobileL} {
    min-width: 55px;
    height: 55px;
  }
`;

export const HeaderIcon = styled(Icon).attrs({
  name: "back",
})`
  font-size: 32px;
  ${responsive.mobileL} {
    font-size: 22px;
  }
`;

export const Title = styled(Heading).attrs({
  align: "center",
})`
  width: 100%;
  ${HeaderButton} + & {
    padding-right: 70px;
    ${responsive.mobileL} {
      padding-right: 55px;
    }
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${({theme}) => theme.divider04};
  padding: 21px 98px;
  box-sizing: border-box;
  ${responsive.mobileL} {
    padding: 13px 20px;
  }
`;
