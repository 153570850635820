import React, {useRef, useState, useCallback} from "react";
import {func, bool, node, arrayOf, oneOf, string, oneOfType} from "prop-types";

import {
  Container,
  Content,
  Section,
  SectionWrapper,
  SectionTitle,
  SectionSubTitle,
  RowWrapper,
  ColumnWrapper,
  ClearAll,
  SectionDropDown,
  DropdownHead,
} from "./TableFilter.style";

import useClickOutside from "../utils/Hooks/useClickOutside";

const FilterContainer = ({
  children,
  onHandleClearAll,
  subTitle,
  clearFilterTestId,
  isClearVisible,
}) => (
  <Container>
    <Content>{children}</Content>
    <ClearAll
      isClearVisible={isClearVisible}
      onClick={onHandleClearAll}
      data-testid={clearFilterTestId}
      text={subTitle}
      narrow
    />
  </Container>
);

FilterContainer.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  onHandleClearAll: func.isRequired,
  subTitle: string.isRequired,
  clearFilterTestId: string,
  isClearVisible: bool,
};

const FilterSection = ({
  title,
  subTitle,
  onSubTitleClick,
  children,
  direction,
  clearSectionTestId,
  value,
}) => {
  const [isVisible, showDropdown] = useState(false);
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    if (isVisible) {
      showDropdown(false);
    }
  });

  const open = useCallback(() => (isVisible ? {} : showDropdown(true)), [isVisible]);
  const clear = useCallback(
    () => {
      showDropdown(false);
      onSubTitleClick();
    },
    [onSubTitleClick],
  );

  return (
    <Section ref={wrapperRef} onClick={open}>
      <DropdownHead
        mt={0}
        label={title}
        input={{value: value || ""}}
        required
        meta={{
          error: undefined,
          touched: false,
        }}
      />
      <SectionDropDown isVisible={isVisible}>
        <SectionWrapper>
          <SectionTitle>{title}</SectionTitle>
          <SectionSubTitle onClick={clear} data-testid={clearSectionTestId}>
            {subTitle}
          </SectionSubTitle>
        </SectionWrapper>
        {direction === "row" ? (
          <RowWrapper>{children}</RowWrapper>
        ) : (
          <ColumnWrapper>{children}</ColumnWrapper>
        )}
      </SectionDropDown>
    </Section>
  );
};

FilterSection.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  onSubTitleClick: func,
  title: string.isRequired,
  subTitle: string,
  direction: oneOf(["row", "column"]),
  clearSectionTestId: string,
  value: string,
};

FilterSection.defaultProps = {
  direction: "column",
};

export default {Container: FilterContainer, Section: FilterSection};
