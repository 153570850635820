import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {textAlignProperty} from "../../helpers";
import P from "../P";

const NoteText = styled(P).attrs({
  as: "pre",
})`
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
`;

NoteText.displayName = "NoteText";

NoteText.propTypes = {
  small: bool,
  light: bool,
  dark: bool,
  align: oneOf(textAlignProperty),
};

NoteText.defaultProps = {
  small: true,
  align: "left",
  dark: true,
};

export default NoteText;
