import React, {useRef, useCallback, useLayoutEffect} from "react";
import {createPortal} from "react-dom";
import {string, bool, func, node, oneOf, number} from "prop-types";

import useClickOutside from "../utils/Hooks/useClickOutside";
import {lockScroll, unlockScroll} from "../utils/scrollLock";
import {
  Wrapper,
  Body,
  DialogHeader,
  DialogTitle,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogAction,
  DialogBack,
  DialogBackIcon,
} from "./Dialog.style";

export const Content = ({
  title,
  onClose,
  action,
  children,
  compact,
  closeOnClickOutside,
  closeDialogTestId,
  backDialogTestId,
  closeOnEscape,
  size,
  withVP,
  withoutHeader,
  onBackIconClick,
  side,
  customWidth,
  customMaxWidth,
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    if (closeOnClickOutside) {
      onClose();
    }
  });

  const keydownListen = useCallback(
    event => {
      if (event.defaultPrevented) {
        return;
      }

      const key = event.key || event.keyCode;

      if (key === "Escape" || key === "Esc" || key === 27) {
        onClose();
      }
    },
    [onClose],
  );

  useLayoutEffect(() => {
    lockScroll();

    return () => {
      unlockScroll();
    };
  }, []);

  useLayoutEffect(
    () => {
      if (closeOnEscape) {
        document.addEventListener("keydown", keydownListen);
      }

      return () => {
        if (closeOnEscape) {
          document.removeEventListener("keydown", keydownListen);
        }
      };
    },
    [closeOnEscape, keydownListen],
  );

  return (
    <Wrapper
      compact={compact}
      withoutHeader={withoutHeader}
      side={side}
      onClick={e => e.stopPropagation()}
    >
      <Body ref={wrapperRef} size={size} withVP={withVP} side={side} customWidth={customWidth}>
        <DialogHeader>
          {onBackIconClick && (
            <DialogBack onClick={onBackIconClick} data-testid={backDialogTestId}>
              <DialogBackIcon />
            </DialogBack>
          )}
          {title && <DialogTitle align="center">{title}</DialogTitle>}
          <DialogClose onClick={onClose} data-testid={closeDialogTestId}>
            <DialogCloseIcon />
          </DialogClose>
        </DialogHeader>
        <DialogContent size={size} withVP={withVP} customMaxWidth={customMaxWidth}>
          {children}
        </DialogContent>
        {action && <DialogAction>{action}</DialogAction>}
      </Body>
    </Wrapper>
  );
};

Content.propTypes = {
  onClose: func.isRequired,
  children: node.isRequired,
  title: string,
  action: node,
  compact: bool,
  closeOnClickOutside: bool,
  closeDialogTestId: string,
  backDialogTestId: string,
  closeOnEscape: bool,
  size: oneOf(["xlarge", "large", "big", "default"]),
  withVP: bool,
  withoutHeader: bool,
  onBackIconClick: func,
  side: oneOf(["left", "right", null]),
  customWidth: number,
  customMaxWidth: number,
};

Content.defaultProps = {
  title: null,
  action: null,
  compact: false,
  closeOnClickOutside: true,
  closeOnEscape: false,
  size: "default",
  withVP: true,
  withoutHeader: false,
  side: null,
  customWidth: null,
  customMaxWidth: null,
};

const Dialog = ({isVisible, ...props}) =>
  isVisible && createPortal(<Content {...props} />, document.body);

Dialog.propTypes = {
  isVisible: bool,
  onClose: func.isRequired,
  title: string,
  action: node,
  closeOnClickOutside: bool,
  withVP: bool,
  side: oneOf(["left", "right", null]),
  customWidth: number,
  customMaxWidth: number,
};

Dialog.defaultProps = {
  isVisible: false,
  title: "",
  action: null,
  closeOnClickOutside: true,
  withVP: true,
  side: null,
  customWidth: null,
  customMaxWidth: null,
};

export {DialogAction};
export default Dialog;
