import styled from "styled-components";

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: ${({mb}) => mb || 0}px;
  margin-top: ${({mt}) => mt || 0}px;
  background-color: ${({theme, dark = false}) => (dark ? theme.divider : theme.divider04)};
`;

export default Divider;
