import React from "react";
import {string, number, oneOfType, node, bool} from "prop-types";
import {MediumText, P, PrimaryText} from "../Text";
import {Column} from "../Flex";

const InfoBlock = ({title, content, mediumText, ...rest}) => {
  const SubTextComponent = mediumText ? MediumText : PrimaryText;

  return (
    <Column {...rest}>
      <P small>{title}</P>
      {typeof content === "string" || typeof content === "number" ? (
        <SubTextComponent as="pre" style={{whiteSpace: "pre-wrap"}}>
          {content}
        </SubTextComponent>
      ) : (
        content
      )}
    </Column>
  );
};

InfoBlock.propTypes = {
  title: string.isRequired,
  content: oneOfType([number, string, node]),
  mediumText: bool,
};

InfoBlock.defaultProps = {
  mediumText: false,
  content: "",
};

export default InfoBlock;
