import React from "react";
import {bool, func, number, oneOfType, shape, string} from "prop-types";

import {
  Input,
  Span,
  SubLabel,
  SubTitle,
  SubValue,
  SubWrapper,
  TitleWrapper,
  Wrapper,
} from "./Checkbox.style";
import {getTitleBySize, RadioCheckboxWrapper} from "../utils/radioChackbox";

const Checkbox = ({
  className,
  disabled,
  input,
  label,
  labelStyles,
  mb,
  mt,
  small,
  style,
  subLabel,
  subTitle,
  subValue,
  testID,
}) => (
  <RadioCheckboxWrapper
    className={className}
    disabled={disabled}
    mb={mb}
    mt={mt}
    small={small}
    style={style}
  >
    <TitleWrapper>
      {getTitleBySize(label, small, labelStyles)}
      {subTitle && <SubTitle align="left">{subTitle}</SubTitle>}
    </TitleWrapper>
    {(!!subLabel.length || !!subValue.length) && (
      <SubWrapper>
        <SubLabel align="right">{subLabel}</SubLabel>
        <SubValue align="right">{subValue}</SubValue>
      </SubWrapper>
    )}
    <Wrapper small={small}>
      <Input {...input} disabled={disabled} data-testid={testID} />
      <Span />
    </Wrapper>
  </RadioCheckboxWrapper>
);

Checkbox.propTypes = {
  className: string,
  disabled: bool,
  input: shape({
    name: string.isRequired,
    onChange: func.isRequired,
    value: oneOfType([string, bool]),
  }).isRequired,
  label: string,
  labelStyles: shape({}),
  mb: number,
  mt: number,
  small: bool,
  style: shape({}),
  subLabel: string,
  subTitle: string,
  subValue: string,
  testID: string,
};

Checkbox.defaultProps = {
  className: "",
  disabled: false,
  label: "",
  labelStyles: {},
  mb: 0,
  mt: 0,
  small: false,
  style: {},
  subLabel: "",
  subTitle: "",
  subValue: "",
  testID: "",
};

export default Checkbox;
