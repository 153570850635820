import React from "react";
import {node, arrayOf, oneOfType} from "prop-types";

import * as S from "./Card.style";

class Card extends React.Component {
  static Header({title, onGoBack, buttonTestID, ...props}) {
    return (
      <S.Header {...props}>
        {onGoBack && (
          <S.HeaderButton onClick={onGoBack} data-testid={buttonTestID}>
            <S.HeaderIcon />
          </S.HeaderButton>
        )}
        <S.Title>{title}</S.Title>
      </S.Header>
    );
  }

  static Footer({children, ...props}) {
    return <S.Footer {...props}>{children}</S.Footer>;
  }

  render() {
    const {children, ...props} = this.props;

    return <S.Wrapper {...props}>{children}</S.Wrapper>;
  }
}

Card.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default Card;
