import styled, {css} from "styled-components";

import Icon from "../Icon";
import Select from "../Select";
import {PrimaryText, Heading} from "../Text";
import {Column} from "../Flex";
import responsive from "../utils/responsive";

const cellStyles = css`
  padding: 20px 10px;
  line-height: 1.38;
  text-align: ${({align}) => align || "left"};
  min-width: ${({minWidth}) => minWidth}px;
  max-width: ${({maxWidth}) => maxWidth}px;
  width: ${({fixedWidth, percentWidth = 10}) =>
    fixedWidth ? `${fixedWidth}px` : `${percentWidth}%`};
  word-wrap: break-word;
  overflow: hidden;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  ${responsive.laptop} {
    padding: 20px 5px;
  }
`;

const defaultTextStyles = css`
  font-weight: 400;
  color: ${({theme}) => theme.textAccent};
`;

const activeHeaderStyles = css`
  font-weight: 500;
  color: ${({theme}) => theme.textMedium};
`;

export const Wrapper = styled(Column)`
  flex: 1;
  position: relative;
`;

export const Container = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  background-color: ${({theme}) => theme.themeWhite};
  min-width: 800px;
`;

export const OverflowWrapper = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 35px;
  width: 100%;
`;

export const Row = styled.tr`
  border-bottom: 1px solid ${({theme}) => theme.divider04};
  &:hover {
    background-color: ${({theme}) => theme.primaryAccent};
  }
`;

export const HeadCell = styled.th`
  font-size: 13px;
  ${({isSorted}) => (isSorted ? activeHeaderStyles : defaultTextStyles)}
  ${cellStyles}
  padding-bottom: 15px;
  user-select: none;
`;

export const BodyCell = styled.td`
  font-size: 14px;
  cursor: ${({clickable}) => (clickable ? "pointer" : "initial")};
  ${defaultTextStyles}
  ${cellStyles}
  color: ${({theme}) => theme.textDark};
`;

export const Head = styled.thead``;

export const HeadRow = styled.tr`
  border-bottom: 1px solid ${({theme}) => theme.divider04};
`;

export const Body = styled.tbody``;

export const SortIcon = styled(Icon).attrs(({isASC}) => ({
  name: isASC ? "down" : "up",
}))`
  color: ${({theme}) => theme.textMedium};
`;

export const PaginationContainer = styled.div`
  background-color: ${({theme}) => theme.themeWhite};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 0;
  bottom: 0;
  left: 0;
  position: sticky;
  box-shadow: ${({theme}) => ` 0 0 7px rgba(${theme.blackShadow}, 0.3)`};
  clip-path: inset(-7px 0 0 0);
  ${responsive.mobileL} {
    padding: 10px 0;
    font-size: 13px;
  }
`;

export const PaginationText = styled(PrimaryText)`
  margin-left: ${({ml = 0}) => ml}px;
  margin-right: ${({mr = 0}) => mr}px;

  ${responsive.mobileL} {
    margin-left: ${({ml = 0}) => ml / 2}px;
    margin-right: ${({mr = 0}) => mr / 2}px;
  }
`;

export const SelectField = styled(Select).attrs(() => ({
  mt: 0,
  style: {width: 55},
}))``;

export const NoDataContainer = styled(Column)`
  margin-left: 35px;
  margin-top: 50px;
`;

export const NoDataTitle = styled(Heading).attrs({
  primary: true,
})`
  margin-bottom: 10px;
`;

export const Gap = styled.div`
  width: 35px;
  min-width: 35px;

  ${responsive.tablet} {
    width: 25px;
    min-width: 25px;
  }
`;
