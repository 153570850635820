import React from "react";
import styled, {keyframes} from "styled-components";

import responsive from "../utils/responsive";
import getSizeByType from "../utils/button";
import Icon from "../Icon";

export const BtnIcon = styled(Icon)`
  margin: 0 5px;
  ${({iconSize}) => iconSize && `font-size: ${iconSize}px;`}
  ${responsive.mobileL} {
    ${({iconSize}) => iconSize && `font-size: ${iconSize / 1.1}px;`}
  }
`;

export const BtnText = styled.div`
  margin: 0;
  text-decoration: ${({underline}) => (underline ? "underline" : "none")};
  ${({fontSize}) => fontSize && `font-size: ${fontSize}px;`}
  ${responsive.mobileL} {
    ${({fontSize}) => fontSize && `font-size: ${fontSize / 1.2}px;`}
  }
`;

// hack to avoid non-boolean attribute
// eslint-disable-next-line react/button-has-type
export const Wrapper = styled(({loading, narrow, ...props}) => <button {...props} />)`
  display: flex;
  text-decoration: none;
  width: ${({narrow}) => (narrow ? "auto" : "100%")};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  padding: ${({size}) => getSizeByType(size, ["4px 28px", "3px 24px", "3px 20px"])};
  min-height: ${({size}) => getSizeByType(size, ["48px", "40px", "33px"])};
  background-color: ${({theme, variant}) => theme.buttons[variant].background};
  border-width: 1px;
  border-color: ${({theme, variant}) => theme.buttons[variant].border};
  border-style: solid;
  border-radius: ${({theme, variant}) => theme.buttons[variant].borderRadius}px;
  color: ${({theme, variant}) => theme.buttons[variant].textColor};
  outline: none;
  opacity: ${({disabled}) => (disabled ? 0.7 : 1)};
  transition: background-color 0.2s ease-out, opacity 0.35s ease, color 0.2s, border-color 0.3s;
  ${({disabled}) => disabled && "pointer-events: none;"}

  &:hover {
    @media not all and (pointer: coarse) {
      background-color: ${({theme, variant}) => theme.buttons[variant].hover.background};
      border-color: ${({theme, variant}) => theme.buttons[variant].hover.border};
      color: ${({theme, variant}) => theme.buttons[variant].hover.textColor};
    }
  }

  &:active {
    transition-duration: 0.05s;
    background-color: ${({theme, variant}) => theme.buttons[variant].active.background};
  }

  ${responsive.mobileL} {
    padding: ${({size}) => getSizeByType(size, ["3px 15px", "2px 10px", "2px 10px"])};
    min-height: ${({size}) => getSizeByType(size, ["40px", "33px", "28px"])};
  }

  ${({loading}) =>
    loading &&
    `
    position: relative;
    ${/* sc-selector */ BtnIcon}, ${/* sc-selector */ BtnText} {
      opacity: 0;
    }
  `}
`;

const loading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(Icon).attrs({
  name: "progress",
})`
  animation: ${loading} 1.2s linear infinite;
  position: absolute;
  margin: auto;
  ${({iconSize}) => iconSize && `font-size: ${iconSize + 3}px;`}
  ${responsive.mobileL} {
    ${({iconSize}) => iconSize && `font-size: ${(iconSize + 3) / 1.1}px;`}
  }
`;
