import styled from "styled-components";

import responsive from "../utils/responsive";
import Icon from "../Icon";

const Text = styled.div`
  letter-spacing: 0.2px;
`;

export const LeftText = styled(Text)`
  margin-left: 10px;
`;

export const RightText = styled(Text)`
  margin-right: 10px;
`;

export const Container = styled.button`
  align-items: center;
  background-color: ${({theme}) => theme.transparent};
  border-radius: 5px;
  border: none;
  color: ${({theme}) => theme.text};
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  min-width: 48px;
  opacity: ${({disabled}) => (disabled ? 0.6 : 1)};
  outline: none;
  padding: 0 7px;
  transition: color 0.2s;
  user-select: none;
  ${({disabled}) => disabled && "pointer-events: none;"}

  ${responsive.mobileL} {
    font-size: 12.5px;
    height: 34px;
    min-width: 34px;
    padding: 4px;
  }

  &:hover {
    @media not all and (pointer: coarse) {
      color: ${({theme}) => theme.textMedium};
    }
  }

  &:active {
    color: ${({theme}) => theme.primary};
    transition-duration: 0s;
  }

  ${({hideTextOnMobile}) =>
    hideTextOnMobile &&
    `
    ${responsive.mobileL} {
      ${LeftText}, ${RightText} {
        display: none;
      }
    }
  `}
`;

export const StyledIcon = styled(Icon)`
  font-size: 24px;

  ${responsive.mobileL} {
    font-size: 18px;
  }
`;
