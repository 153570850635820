import React, {useEffect} from "react";
import {arrayOf, node, oneOfType, func, bool, string} from "prop-types";

const CallbackForm = ({onSubmit, submitSucceeded, onSuccessCallback, children, id}) => {
  useEffect(
    () => {
      if (submitSucceeded && typeof onSuccessCallback === "function") {
        onSuccessCallback();
      }
    },
    [onSuccessCallback, submitSucceeded],
  );

  return (
    <form noValidate onSubmit={onSubmit} id={id}>
      {children}
    </form>
  );
};

CallbackForm.propTypes = {
  onSubmit: func.isRequired,
  onSuccessCallback: func.isRequired,
  submitSucceeded: bool.isRequired,
  id: string,
  children: oneOfType([arrayOf(node), node]),
};

export default CallbackForm;
