import styled from "styled-components";
import React from "react";
import {P, PrimaryText} from "../Text";

export const RadioCheckboxWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  cursor: ${({disabled}) => (disabled ? "initial" : "pointer")};
  user-select: none;
  padding: ${({small}) => (small ? 2 : 14)}px 0;
  position: relative;
  align-items: center;
  margin-top: ${({mt}) => mt}px;
  margin-bottom: ${({mb}) => mb}px;
  ${PrimaryText}, ${P} {
    word-wrap: break-word;
    max-width: calc(100% - 30px);
  }
  &:active {
    background: ${({theme}) => theme.primaryAccent};
  }
  &::after {
    position: absolute;
    display: block;
    content: "";
    height: ${({small}) => (small ? 0 : 1)}px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${({theme}) => theme.divider};
  }

  ${({disabled}) =>
    disabled &&
    `
     pointer-events: none;
     opacity 0.6;
  `}
`;

export const getTitleBySize = (label, small = false, style = {}) =>
  small ? (
    <P small style={style}>
      {label}
    </P>
  ) : (
    <PrimaryText style={style}>{label}</PrimaryText>
  );
