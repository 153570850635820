import styled from "styled-components";

import {PrimaryText, withAlign} from "../Text";

export const Input = styled.input.attrs({
  type: "checkbox",
})`
  appearance: none;
  border-radius: 50%;
  box-shadow: none;
  display: block;
  height: 1px;
  left: 0;
  margin: 0;
  opacity: 0;
  outline: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.2s;
  width: 1px;
  z-index: -1;

  &:checked {
    background-color: ${({theme}) => theme.primary};
  }

  &:checked + span::before {
    background-color: ${({theme}) => theme.primary};
    border-color: ${({theme}) => theme.primary};
  }

  &:checked + span::after,
  &:indeterminate + span::after {
    border-color: ${({theme}) => theme.white};
  }

  &:indeterminate + span::after {
    border-color: ${({theme}) => theme.white};
    border-left: none;
    transform: translate(4px, 3px);
  }
`;

export const Span = styled(PrimaryText).attrs({
  as: "span",
  primary: true,
})`
  cursor: pointer;
  display: inline-block;
  width: 22px;

  /* Box */
  &::before {
    border: solid 2px; /* Safari */
    border-color: ${({theme}) => theme.textAccent};
    border-radius: 2px;
    content: "";
    display: inline-block;
    height: 20px;
    margin: 3px 11px 3px 1px;
    transition: border-color 0.2s, background-color 0.2s;
    vertical-align: top;
    width: 20px;
  }

  &::after {
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    content: "";
    display: block;
    height: 7px;
    left: 2px;
    position: absolute;
    top: 4px;
    transform: translate(3px, 4px) rotate(-45deg);
    width: 12px;
  }
`;

export const SubLabel = styled(withAlign)`
  color: ${({theme}) => theme.textMedium};
  font-size: 14px;
  line-height: 15px;
`;

export const SubTitle = styled(withAlign)`
  color: ${({theme}) => theme.textAccent};
  font-size: 13px;
  line-height: 15px;
`;

export const SubWrapper = styled.div`
  margin: -2.5px 0;
  margin-left: auto;
  margin-right: 5px;
  min-width: 50px;
`;

export const SubValue = styled(withAlign)`
  color: ${({theme}) => theme.text};
  font-size: 11px;
  line-height: 13px;
`;

export const TitleWrapper = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  flex-shrink: 0;
  font-size: 0;
  position: relative;
  ${({small}) => small && `transform: scale(0.8);`}

  & > input:active + span::before {
    border-color: ${({theme}) => theme.primary};
  }

  & > input:checked:active + span::before {
    border-color: transparent;
  }
`;
