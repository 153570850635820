import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {textAlignProperty} from "../../helpers";
import P from "../P";

const LinkText = styled(P)`
  color: ${({theme}) => theme.primary};
  text-decoration: underline;
`;

LinkText.displayName = "LinkText";

LinkText.propTypes = {
  small: bool,
  light: bool,
  align: oneOf(textAlignProperty),
};

LinkText.defaultProps = {
  small: false,
  align: "left",
};

export default LinkText;
