import styled from "styled-components";

import Icon from "../Icon";
import {P} from "../Text";

export const NavText = styled(P)`
  color: currentColor;
  font-size: 17px;
  opacity: 1;
  transition: opacity 0.2s;
`;

export const NavIcon = styled(Icon)`
  color: currentColor;
  flex-shrink: 0;
  font-size: 25px;
  line-height: 25px;
  margin-right: 17px;
  opacity: 0.75;

  &::before {
    margin: 0;
  }
`;

export const Container = styled.a`
  align-items: center;
  border-radius: 10px;
  color: ${({theme}) => theme.white};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: 54px;
  padding: 12px 15px;
  text-decoration: none;
  transition: all 0.2s;

  &.active {
    background-color: ${({theme, variant}) =>
      variant === "admin" ? theme.primary05 : theme.white013};

    ${NavIcon} {
      opacity: 1;
    }
  }

  &:hover {
    background-color: ${({theme, variant}) =>
      variant === "admin" ? theme.primary034 : theme.textDark024};
  }

  ${({isDisabled}) =>
    isDisabled &&
    `
      pointer-events: none;
  `}
`;
