import styled from "styled-components";

const styledText = styled.p`
  font-family: ${({theme}) => theme.font};
  b,
  strong {
    font-weight: 500;
  }
  a {
    color: ${({theme}) => theme.primary};
  }
`;

export default styledText;
