import React, {useContext, useEffect, useRef, useState} from "react";
import {arrayOf, node, oneOfType} from "prop-types";
import {CollapseContent, CollapseView} from "../RPAccordion.style";
import {ItemContext} from "./Context";

export const Collapse = ({children, ...rest}) => {
  const ref = useRef();
  const [height, setHeight] = useState(null);
  const {isOpened} = useContext(ItemContext);

  useEffect(
    () => {
      const handleResize = () => setHeight(ref.current?.offsetHeight);

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    },
    [children],
  );

  const computedStyleOptions = {
    maxHeight: isOpened ? height : 0,
    opacity: isOpened ? 1 : 0,
  };

  return (
    <CollapseView style={computedStyleOptions}>
      <CollapseContent ref={ref} {...rest}>
        {children}
      </CollapseContent>
    </CollapseView>
  );
};

Collapse.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

Collapse.defaultProps = {
  children: null,
};
