import React from "react";
import {string, shape, bool, func, number} from "prop-types";

import {I, Input, Label, Span} from "./Switch.style";

const Switch = ({input, label, disabled, mt, mb, style, className, testID}) => (
  <Label
    disabled={disabled}
    mt={mt}
    mb={mb}
    style={style}
    className={className}
    data-testid={testID}
  >
    <Input {...input} disabled={disabled} />
    <I />
    <Span>{label}</Span>
  </Label>
);

Switch.propTypes = {
  label: string,
  input: shape({
    value: string,
    name: string.isRequired,
    checked: bool.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  disabled: bool,
  mt: number,
  mb: number,
  style: shape({}),
  className: string,
  testID: string,
};

Switch.defaultProps = {
  label: "",
  disabled: false,
  mt: 12,
  mb: 12,
  style: {},
  className: "",
};

export default Switch;
