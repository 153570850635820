import styled from "styled-components";

import responsive from "../utils/responsive";

import {SubTitle} from "../Text";

export const List = styled.div`
  position: absolute;
  z-index: 20;
  padding: 9px 1px;
  min-width: 180px;
  box-shadow: ${({theme}) =>
    `2px 2px 10px 0 rgba(${theme.blackShadow}, 0.12), 0 1px 3px 0 rgba(${theme.blackShadow}, 0.1)`};
  border: solid 1px ${({theme}) => theme.divider};
  border-radius: 10px;
  background: ${({theme}) => theme.themeWhite};
  ${({alignment}) => `${alignment}: 0`};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
  transition: opacity 0.2s, visibility 0.2s;
  user-select: none;
  ${responsive.mobileL} {
    border-radius: 5px;
  }
`;

export const Label = styled(SubTitle)`
  font-size: 18px;
  color: ${({theme}) => theme.primary};
  transition: color 0.2s;
  line-height: 1.3;
  letter-spacing: 0.2px;
  white-space: nowrap;
`;

export const Item = styled.button`
  padding: 12px 28px;
  list-style: none;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background: ${({theme}) => theme.transparent};
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-weight: 500;
  &:not(:last-of-type)::after {
    content: "";
    position: absolute;
    height: 1.1px;
    left: 22px;
    right: 22px;
    bottom: 0;
    background: ${({theme}) => theme.divider04};
  }

  &:hover {
    ${Label} {
      color: ${({theme}) => theme.primaryDark};
    }
  }

  &:active {
    background: ${({theme}) => theme.primaryAccent};
  }

  ${responsive.mobileL} {
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
  }
`;
