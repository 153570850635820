import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {withAlign, textAlignProperty} from "../../helpers";
import responsive from "../../../utils/responsive";

const P = styled(withAlign)`
  color: ${({theme, light}) => (light ? theme.primaryLightMedium : theme.text)};
  flex-shrink: 1;
  font-size: ${({small}) => (small ? "14" : "16")}px;
  line-height: ${({small}) => (small ? "1.29" : "1.38")};
  letter-spacing: 0.21px;
  ${responsive.mobileL} {
    font-size: ${({small}) => (small ? "14" : "13")}px;
  }
`;

P.displayName = "P";

P.propTypes = {
  light: bool,
  small: bool,
  align: oneOf(textAlignProperty),
};

P.defaultProps = {
  light: false,
  small: false,
  align: "left",
};

export default P;
