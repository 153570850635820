import styled from "styled-components";

import {Row} from "../Flex";
import Icon from "../Icon";
import {Caption, P} from "../Text";
import responsive from "../utils/responsive";

const getColorByType = (type, isPrimary, theme) => {
  const types = {
    success: {
      icon: theme.success,
      text: theme.success,
      withTextType: theme.success,
    },
    error: {
      icon: theme.error,
      text: isPrimary ? theme.textMedium : theme.textDark,
      withTextType: theme.error,
    },
    warning: {
      icon: theme.warning,
      text: isPrimary ? theme.textMedium : theme.textDark,
      withTextType: theme.warning,
    },
    info: {
      icon: theme.primary,
      text: theme.primary,
      withTextType: theme.primary,
    },
    default: {
      icon: theme.text,
      text: theme.text,
      withTextType: theme.text,
    },
    dark: {
      icon: theme.textDark,
      text: theme.textDark,
      withTextType: theme.textDark,
    },
  };

  return types[type] || types.default;
};

export const Note = styled(P)`
  font-size: 10px;
  padding-left: 3px;
  color: ${({theme, type, isPrimary}) =>
    type ? getColorByType(type, isPrimary, theme).text : theme.textMedium};
`;

export const UnderText = styled(P)`
  font-size: 10px;
  padding-left: 4px;
  color: ${({theme}) => theme.text};
`;

export const StatusIcon = styled(Icon)`
  font-size: ${({fs}) => (fs ? `${fs}px` : "calc(1em + 5px)")};
  color: ${({theme, type, isPrimary}) => getColorByType(type, isPrimary, theme).icon};
  &::before {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const StatusText = styled(Caption)`
  font-size: 1em;
  letter-spacing: 0.64px;
  line-height: 1.04;
  padding-left: 3px;
  white-space: nowrap;
`;

export const Wrapper = styled(Row)`
  align-items: center;
  ${({isPrimary, theme, type, textType}) => `
    font-size: ${isPrimary ? 16 : 15}px;
    line-height: 1;
    ${responsive.tablet} {
      font-size: ${isPrimary ? 13 : 12}px;
    }
    ${StatusText} {
      color: ${getColorByType(type, isPrimary, theme)[textType ? "withTextType" : "text"]};
    }
    ${isPrimary &&
      `
      ${StatusText}, ${Note} {
        font-weight: 500;
        letter-spacing: 0.23px;
      }
    `}

  `}
`;

export const BottomWrapper = styled(Row)`
  padding-left: 3px;
  align-items: center;
`;
