import React from "react";
import {string} from "prop-types";
import "react-toastify/dist/ReactToastify.min.css";
import {useTheme} from "styled-components";

import Loader from "../Loader";
import Toaster from "../Toaster";

import * as S from "./styles";

const DownloadToaster = ({title}) => {
  const theme = useTheme();

  return <S.Container title={title} iconComponent={<Loader color={theme.white} size="small" />} />;
};

DownloadToaster.propTypes = {
  title: string,
};

const toastId = React.createRef();

toastId.current = [];

const show = props => {
  const newToastId = Toaster.toast(<DownloadToaster {...props} />, {
    autoClose: 999999999,
    draggable: false,
    closeOnClick: false,
  });

  toastId.current.push(newToastId);
};

const dismiss = () => {
  const lastElem = toastId.current.pop();

  Toaster.toast.dismiss(lastElem);
};

export default {show, dismiss};
