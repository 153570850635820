import styled from "styled-components";
import responsive from "../utils/responsive";
import {Row} from "../Flex";
import {Wrapper} from "../Button/Button.style";

export const ActionWrapper = styled(Row)`
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.divider04};
  min-height: 67px;
  justify-content: space-between;
  padding: 9px;

  ${responsive.mobileL} {
    padding: 5px 9px;
    min-height: 44px;
  }
`;

export const ActionRight = styled(Row)`
  align-items: center;
  ${Wrapper} {
    & + ${Wrapper} {
      margin-left: 5px;
    }
  }
`;
