import React, {Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {arrayOf, bool, elementType, func, node, oneOf, oneOfType, shape, string} from "prop-types";

import CanopyLogo from "../CanopyLogo";
import CoBrandingLogo from "../CoBrandingLogo";
import PersonaCard from "../PersonaCard";
import {size} from "../utils/responsive";
import {lockScroll, unlockScroll} from "../utils/scrollLock";

import {
  AppBar,
  Content,
  Menu,
  MenuIcon,
  MenuMain,
  Nav,
  NavContent,
  SectionTitle,
  Wrapper,
} from "./BaseLayout.style";

const BaseLayout = ({
  branchName,
  children,
  coBrandingLogoSrc,
  coBrandingText,
  companyName,
  companyTestId,
  isDefaultOpen,
  logoSrc,
  mainNavigation,
  menuIconTestId,
  mobileLogoSrc,
  navFooter,
  onCompanyNameClick,
  onToggle,
  onUserNameClick,
  shortCoBrandingLogoSrc,
  shortLogoSrc,
  sidebarTopOffset,
  userName,
  userRole,
  userTestId,
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(() =>
    window.matchMedia(`(max-width: ${size.laptop}px)`).matches ? false : !isDefaultOpen,
  );
  const userCardRef = useRef();

  const toggle = useCallback(
    () => {
      setIsOpen(prevIsOpen => {
        if (onToggle) {
          onToggle(
            window.matchMedia(`(max-width: ${size.laptop}px)`).matches ? !prevIsOpen : prevIsOpen,
          );
        }

        return !prevIsOpen;
      });
    },
    [onToggle],
  );

  const handleMenuClick = useCallback(
    event => {
      if (
        window.matchMedia(`(max-width: ${size.laptop}px)`).matches &&
        !userCardRef.current.contains(event.target)
      ) {
        toggle();
      }
    },
    [toggle],
  );

  useLayoutEffect(
    () => {
      if (!isOpen) {
        unlockScroll();
      } else {
        lockScroll(size.laptop);
      }
    },
    [isOpen],
  );

  useEffect(() => () => unlockScroll());

  return (
    <Wrapper id="dashboard">
      <Nav isOpen={isOpen} sidebarTopOffset={sidebarTopOffset} variant={variant}>
        <AppBar>
          <NavContent pl={isOpen ? 10 : 20}>
            <CanopyLogo
              logoSrc={logoSrc}
              mobileLogoSrc={mobileLogoSrc}
              shortLogoSrc={shortLogoSrc}
            />
          </NavContent>
          <MenuIcon data-testid={menuIconTestId} isOpen={isOpen} onClick={toggle} />
        </AppBar>
        <Menu isOpen={isOpen} variant={variant} onClick={handleMenuClick}>
          <NavContent pl={isOpen ? 10 : 20} overflowY="unset">
            {variant === "hq" && coBrandingLogoSrc && (
              <CoBrandingLogo
                logoSrc={coBrandingLogoSrc}
                shortLogoSrc={shortCoBrandingLogoSrc}
                text={coBrandingText}
              />
            )}
          </NavContent>
          {variant === "hq" && companyName && (
            <PersonaCard
              onClick={onCompanyNameClick}
              primaryInfo={companyName}
              secondaryInfo={branchName}
              testId={companyTestId}
            />
          )}
          <MenuMain isOpen={isOpen}>
            {Array.isArray(mainNavigation)
              ? mainNavigation.map(section => (
                  <Fragment key={section.title?.name}>
                    <SectionTitle isOpen={isOpen}>
                      {isOpen && !window.matchMedia(`(max-width: ${size.laptop}px)`).matches
                        ? section.title.shortName
                        : section.title.name}
                    </SectionTitle>
                    {section.items}
                  </Fragment>
                ))
              : mainNavigation}
          </MenuMain>
          {navFooter}
          <PersonaCard
            onClick={onUserNameClick}
            primaryInfo={userName}
            ref={userCardRef}
            secondaryInfo={userRole}
            testId={userTestId}
            type="white"
          />
        </Menu>
      </Nav>
      <Content isOpen={isOpen}>{children}</Content>
    </Wrapper>
  );
};

BaseLayout.propTypes = {
  branchName: string,
  children: node,
  coBrandingLogoSrc: oneOfType([elementType, string]),
  coBrandingText: string,
  companyName: string,
  companyTestId: string,
  isDefaultOpen: bool,
  logoSrc: oneOfType([elementType, string]).isRequired,
  mainNavigation: oneOfType([
    node,
    arrayOf(
      shape({
        title: shape({
          name: string.isRequired,
          shortName: string.isRequired,
        }).isRequired,
        items: node,
      }),
    ),
  ]),
  menuIconTestId: string,
  mobileLogoSrc: string,
  navFooter: node,
  onCompanyNameClick: func,
  onToggle: func,
  onUserNameClick: func,
  shortCoBrandingLogoSrc: string,
  shortLogoSrc: string.isRequired,
  sidebarTopOffset: bool,
  userName: string,
  userRole: string,
  userTestId: string,
  variant: oneOf(["admin", "hq"]),
};

BaseLayout.defaultProps = {
  branchName: "",
  children: null,
  coBrandingLogoSrc: "",
  coBrandingText: "",
  companyName: "",
  companyTestId: "",
  isDefaultOpen: true,
  mainNavigation: null,
  menuIconTestId: "",
  mobileLogoSrc: "",
  navFooter: null,
  onCompanyNameClick: () => {},
  onToggle: null,
  onUserNameClick: () => {},
  shortCoBrandingLogoSrc: null,
  sidebarTopOffset: false,
  userName: "",
  userRole: "",
  userTestId: "",
  variant: "hq",
};

export default BaseLayout;
