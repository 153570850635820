import React from "react";
import {string} from "prop-types";

import {CoBrandingImage, Container, InPartnership, ShortCoBrandingImage} from "./style";

const CoBrandingLogo = ({logoSrc, shortLogoSrc, text}) => (
  <Container>
    <ShortCoBrandingImage src={shortLogoSrc} />
    <InPartnership>{text}</InPartnership>
    <CoBrandingImage src={logoSrc} />
  </Container>
);

CoBrandingLogo.propTypes = {
  logoSrc: string.isRequired,
  shortLogoSrc: string.isRequired,
  text: string,
};

export default CoBrandingLogo;
