import React from "react";
import {func, string, node, shape} from "prop-types";
import {P} from "../Text";
import {Card, Title, ActionButton, Icon, CardContent, CardFooter} from "./InfoCard.style";

const InfoCard = ({
  title,
  subTitle,
  icon,
  onBtnClick,
  btnText,
  customBtn,
  style,
  className,
  titleTestID,
  iconTestID,
  subTitleTestID,
}) => (
  <Card style={style} className={className}>
    <CardContent>
      {icon && <Icon name={icon} data-testid={iconTestID} />}
      {title && <Title data-testid={titleTestID}>{title}</Title>}
      {subTitle && (
        <P align="center" data-testid={subTitleTestID}>
          {subTitle}
        </P>
      )}
    </CardContent>
    {(customBtn || onBtnClick) && (
      <CardFooter>{customBtn || <ActionButton text={btnText} onClick={onBtnClick} />}</CardFooter>
    )}
  </Card>
);

InfoCard.propTypes = {
  icon: string,
  title: string,
  titleTestID: string,
  subTitleTestID: string,
  iconTestID: string,
  subTitle: string,
  btnText: string,
  onBtnClick: func,
  customBtn: node,
  style: shape({}),
  className: string,
};

InfoCard.defaultProps = {
  icon: "",
  title: "",
  subTitle: "",
  btnText: "",
  style: {},
  className: "",
};

export default InfoCard;
