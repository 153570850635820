import React from "react";
import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {withAlign, textAlignProperty} from "../../helpers";
import responsive from "../../../utils/responsive";

const Component = withAlign;

// hack to avoid non-boolean attribute
const Caption = styled(({mobileSmall, ...props}) => <Component {...props} />)`
  color: ${({theme}) => theme.text};
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  ${({mobileSmall}) =>
    mobileSmall &&
    `${responsive.mobileL} {
    font-size: 11px;
    }`}
`;

Caption.displayName = "Caption";

Caption.propTypes = {
  mobileSmall: bool,
  align: oneOf(textAlignProperty),
};

Caption.defaultProps = {
  mobileSmall: false,
  align: "left",
};

export default Caption;
