import styled from "styled-components";
import {oneOf} from "prop-types";
import {withAlign, textAlignProperty} from "../../helpers";
import responsive from "../../../utils/responsive";

const Title = styled(withAlign)`
  color: ${({theme}) => theme.primaryDarkGrey};
  font-size: 22px;
  line-height: 1.1;
  font-weight: 500;
  ${responsive.mobileL} {
    font-size: 17px;
  }
`;

Title.displayName = "Title";

Title.propTypes = {
  align: oneOf(textAlignProperty),
};

Title.defaultProps = {
  align: "left",
};

export default Title;
