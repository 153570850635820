import React, {memo} from "react";
import {string, node, shape, oneOf} from "prop-types";

import StatusText from "../StatusText";

import {
  Wrapper,
  FlexContainer,
  Label,
  Value,
  Header,
  SubHeader,
  EllipsisContainer,
  Divider,
} from "./RentPassportTableCard.style";

const RentPassportTableCard = ({header, subHeader, reflectData, reflectComponent, list}) => (
  <Wrapper>
    <FlexContainer align="center" jc="space-between">
      <EllipsisContainer>
        <Header>{header}</Header>
        <SubHeader>{subHeader}</SubHeader>
      </EllipsisContainer>
      <FlexContainer column align="center" width="80px">
        {reflectComponent || (
          <StatusText
            adaptive
            iconName={reflectData.iconName}
            text={reflectData.text}
            type={reflectData.type}
          />
        )}
      </FlexContainer>
    </FlexContainer>
    <Divider mt={12} mb={10} />
    <>
      {Object.keys(list).map(el => (
        <FlexContainer key={`${el}_${list[el]}`}>
          <Label>{el}</Label>
          <Value>{list[el]}</Value>
        </FlexContainer>
      ))}
    </>
  </Wrapper>
);

RentPassportTableCard.propTypes = {
  header: string.isRequired,
  subHeader: string.isRequired,
  reflectData: shape({
    iconName: string.isRequired,
    text: string.isRequired,
    type: oneOf(["error", "info", "warning", "success"]),
  }),
  reflectComponent: node,
  list: shape({}).isRequired,
};

export default memo(RentPassportTableCard);
