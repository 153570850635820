import React from "react";
import {string, shape, bool, func, number} from "prop-types";

import {Input, Circle} from "./Radio.style";
import {getTitleBySize, RadioCheckboxWrapper} from "../utils/radioChackbox";

const CircleInner = () => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
    <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z" />
  </svg>
);

const CircleOuter = () => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </svg>
);

const Radio = ({input, label, disabled, small, labelStyles, mt, mb, style, className, testID}) => (
  <RadioCheckboxWrapper
    small={small}
    disabled={disabled}
    mt={mt}
    mb={mb}
    style={style}
    className={className}
  >
    <Input {...input} disabled={disabled} data-testid={testID} />
    {getTitleBySize(label, small, labelStyles)}
    <Circle small={small}>
      <CircleInner />
      <CircleOuter />
    </Circle>
  </RadioCheckboxWrapper>
);

Radio.propTypes = {
  label: string,
  input: shape({
    value: string,
    name: string.isRequired,
    checked: bool.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  disabled: bool,
  small: bool,
  labelStyles: shape({}),
  mt: number,
  mb: number,
  style: shape({}),
  className: string,
  testID: string,
};

Radio.defaultProps = {
  label: "",
  disabled: false,
  small: false,
  labelStyles: {},
  mt: 0,
  mb: 0,
  style: {},
  className: "",
  testID: "",
};

export default Radio;
