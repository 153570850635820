import React from "react";
import {arrayOf, node, oneOfType, string, func} from "prop-types";
import IconButton from "../IconButton";
import {ActionWrapper, ActionRight} from "./ActionHead.style";

const ActionHead = ({backText, children, onBack, backBtnTestId}) => (
  <ActionWrapper>
    <IconButton
      onClick={onBack}
      icon="arrow-left"
      rightText={backText}
      data-testid={backBtnTestId}
    />
    {children && <ActionRight>{children}</ActionRight>}
  </ActionWrapper>
);

ActionHead.propTypes = {
  backBtnTestId: string,
  backText: string.isRequired,
  onBack: func,
  children: oneOfType([arrayOf(node), node]),
};

ActionHead.defaultProps = {
  children: null,
  onBack: () => {},
};
export default ActionHead;
