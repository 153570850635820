import React from "react";
import {arrayOf, node, oneOfType, string} from "prop-types";
import {Heading} from "../Text";

import {HeadWrapper, ChildrenWrapper, SubHeadComponent} from "./PageHead.style";

const PageHead = ({title, children, subHead}) => (
  <HeadWrapper hasSubHead={!!subHead}>
    <Heading primary>{title}</Heading>
    {subHead && <SubHeadComponent>{subHead}</SubHeadComponent>}
    {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
  </HeadWrapper>
);

PageHead.propTypes = {
  title: string.isRequired,
  children: oneOfType([arrayOf(node), node]),
  subHead: oneOfType([arrayOf(node), node]),
};

PageHead.defaultProps = {
  children: null,
};

export default PageHead;
