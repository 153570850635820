import Caption from "./components/Caption";
import Heading from "./components/Heading";
import LinkText from "./components/LinkText";
import MediumText from "./components/MediumText";
import NoteText from "./components/NoteText";
import P from "./components/P";
import PrimaryText from "./components/PrimaryText";
import SubTitle from "./components/SubTitle";
import Title from "./components/Title";

import withAlign from "./helpers/withAlign";

export {LinkText, SubTitle, Title, Heading, PrimaryText, MediumText, P, Caption, withAlign};

export default {
  LinkText,
  SubTitle,
  Title,
  Heading,
  PrimaryText,
  MediumText,
  NoteText,
  P,
  Caption,
};
