import React from "react";
import {bool, node, string} from "prop-types";
import {SectionHeading, SectionTitleView, SectionInfo} from "../RPAccordion.style";

export const Section = ({info, title, withTopBorder, testID}) => (
  <SectionTitleView withTopBorder={withTopBorder} data-testid={testID}>
    <SectionHeading>{title}</SectionHeading>
    {info && <SectionInfo>{info}</SectionInfo>}
  </SectionTitleView>
);

Section.propTypes = {
  info: node,
  title: string.isRequired,
  withTopBorder: bool,
  testID: string,
};

Section.defaultProps = {
  info: null,
  withTopBorder: true,
  testID: "",
};
