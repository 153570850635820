import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {textAlignProperty} from "../../helpers";
import responsive from "../../../utils/responsive";
import P from "../P";

const MediumText = styled(P)`
  color: ${({theme}) => theme.textDark};
  font-size: 18px;
  line-height: 1.38;
  ${responsive.mobileL} {
    font-size: 16px;
  }
`;

MediumText.displayName = "MediumText";

MediumText.propTypes = {
  light: bool,
  small: bool,
  align: oneOf(textAlignProperty),
};

MediumText.defaultProps = {
  align: "left",
};

export default MediumText;
