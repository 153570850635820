import React from "react";
import {bool, oneOf, string} from "prop-types";

import {Column} from "../Flex";
import {Note, StatusIcon, StatusText, Wrapper, BottomWrapper, UnderText} from "./Status.style";

const Status = ({
  bottomNote,
  iconName,
  bottomIconName,
  iconType,
  bottomIconType,
  isPrimary,
  text,
  topNote,
  type,
  bottomNoteType,
  topNoteType,
  underText,
  statusIconStyle,
  textType,
  ...rest
}) => (
  <Wrapper isPrimary={isPrimary} type={type} textType={textType} {...rest}>
    {iconName && <StatusIcon name={iconName} type={iconType || type} style={statusIconStyle} />}
    <Column>
      {topNote && <Note type={topNoteType}>{topNote}</Note>}
      <StatusText>{text}</StatusText>
      {bottomNote && (
        <BottomWrapper>
          {bottomIconName && <StatusIcon fs={12} name={bottomIconName} type={bottomIconType} />}
          <Note type={bottomNoteType}>{bottomNote}</Note>
        </BottomWrapper>
      )}
      {underText && <UnderText type={bottomNoteType}>{underText}</UnderText>}
    </Column>
  </Wrapper>
);

Status.propTypes = {
  bottomNote: string,
  iconName: string,
  bottomIconName: string,
  isPrimary: bool,
  text: string,
  topNote: string,
  underText: string,
  type: oneOf(["error", "info", "warning", "success", "default", "dark"]),
  textType: oneOf(["error", "info", "warning", "success", "default", "dark"]),
  iconType: oneOf(["error", "info", "warning", "success", "default", "dark"]),
  bottomIconType: oneOf(["error", "info", "warning", "success", "default", "dark"]),
  bottomNoteType: oneOf(["error", "info", "warning", "success", "default", "dark"]),
  topNoteType: oneOf(["error", "info", "warning", "success", "default", "dark"]),
  statusIconStyle: {},
};

Status.defaultProps = {
  bottomNote: "",
  iconName: "",
  bottomIconName: "",
  isPrimary: false,
  text: "",
  topNote: "",
  type: "default",
  textType: "",
};

export default Status;
