import styled from "styled-components";

import {CoBrandingImage, InPartnership, ShortCoBrandingImage} from "../CoBrandingLogo/style";
import {InfoContainer} from "../PersonaCard/PersonaCard.style";
import {LogoImage, ShortLogoImage} from "../CanopyLogo/CanopyLogo.style";
import {Column, Row} from "../Flex";
import Icon from "../Icon";
import {Container as NavItemContainer, NavText} from "../NavItem/NavItem.style";
import {P} from "../Text";
import {
  headerHeightDesktop,
  headerHeightLaptop,
  headerHeightMobile,
} from "../utils/baseLayoutConstants";
import responsive, {size} from "../utils/responsive";

const navWidth = 320;
const navWidthHidden = 80;
const diff = navWidth - navWidthHidden;
const navTopOffset = 54;

const matchesLaptop = () => window.matchMedia(`(max-width: ${size.laptop}px)`).matches;

export const AppBar = styled(Row)`
  position: relative;
  width: 100%;
`;

export const Content = styled(Column)`
  background-color: ${({theme}) => theme.themeWhite};
  box-shadow: ${({theme}) =>
    `0 0 16px -5px rgba(${theme.brandShadow}, 0.15), 0 0 5px 0 rgba(${theme.brandShadow}, 0.05)`};
  grid-area: content;
  justify-content: flex-start;
  margin-left: auto;
  overflow: auto;
  position: relative;
  transition: width 0.22s cubic-bezier(0.2, 0, 0, 1);
  width: calc(100% - ${({isOpen}) => (!isOpen ? diff : 0)}px);

  ${responsive.laptop} {
    box-shadow: none;
    width: 100%;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.divider02};
  width: 100%;
`;

export const Menu = styled.nav`
  background-color: ${({theme, variant}) =>
    variant === "hq" ? theme.navigationPrimaryHQ : theme.navigationPrimaryAdmin};
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  justify-content: space-between;
  min-height: 0;
  user-select: none;
  width: 100%;

  ${responsive.laptop} {
    bottom: 0;
    box-shadow: ${({theme}) =>
      `0 0 16px -5px rgba(${theme.brandShadow}, 0.15), 0 0 5px 0 rgba(${theme.brandShadow}, 0.05)`};
    position: fixed;
    right: 0;
    top: ${headerHeightLaptop}px;
    transition: transform 0.3s ease-in-out;
    z-index: 105;
  }

  ${responsive.mobileL} {
    max-width: 100%;
    padding-top: 0;
    top: ${headerHeightMobile}px;
  }
`;

export const MenuIcon = styled(Icon).attrs(({isOpen}) => {
  // soo, here we don't want to duplicate components, so for each icon change we check for window width
  // to set proper icon name
  if (matchesLaptop()) {
    return {name: isOpen ? "close" : "menu"};
  }

  return {name: isOpen ? "menu-right" : "menu-left"};
})`
  color: ${({theme}) => theme.white};
  cursor: pointer;
  font-size: 20px;
  height: ${headerHeightDesktop}px;
  line-height: ${headerHeightDesktop}px;
  margin-top: 10px;
  opacity: 0.75;
  position: absolute;
  right: ${({isOpen}) => (isOpen ? `${diff}` : 10)}px;
  z-index: 1;

  &:hover {
    @media not all and (pointer: coarse) {
      opacity: 1;
    }
  }

  ${responsive.laptop} {
    height: ${headerHeightLaptop}px;
    line-height: ${headerHeightLaptop}px;
    margin-top: 0;
    padding: 0 5px;
    right: 10px;
    z-index: 1;
  }

  ${responsive.mobileL} {
    height: ${headerHeightMobile}px;
    line-height: ${headerHeightMobile}px;
    margin-top: 0;
  }
`;

export const MenuMain = styled.div`
  margin-bottom: auto;
  overflow: auto;
  padding-bottom: 50px;
  padding-left: ${({isOpen}) => (isOpen ? 12 : 8)}px;
  padding-right: 8px;
  padding-top: 12px;
  scrollbar-width: none;
  transition: all 0.2s;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const NavContent = styled(Column)`
  margin-bottom: ${({mb = 0}) => mb}px;
  margin-left: ${({ml = 0}) => ml}px;
  margin-right: ${({mr = 0}) => mr}px;
  margin-top: ${({mt = 0}) => mt}px;
  overflow-y: ${({overflowY = "auto"}) => overflowY};
  padding-bottom: ${({pb = 0}) => pb}px;
  padding-left: ${({pl = 0}) => pl}px;
  padding-right: ${({pr = 0}) => pr}px;
  padding-top: ${({pt = 0}) => pt}px;
  transition: all 0.2s;
  width: 100%;
`;

export const Nav = styled(Column)`
  align-items: stretch;
  background-color: ${({theme, variant}) =>
    variant === "hq" ? theme.navigationPrimaryHQ : theme.navigationPrimaryAdmin};
  grid-area: nav;
  height: ${({sidebarTopOffset}) => (sidebarTopOffset ? `calc(100% - ${navTopOffset}px)` : "100%")};
  left: 0;
  position: fixed;
  top: ${({sidebarTopOffset}) => (sidebarTopOffset ? navTopOffset : 0)}px;
  width: ${navWidth}px;

  ${/* sc-selector */ NavItemContainer} {
    ${({isOpen}) => isOpen && `width: 56px;`}
  }

  ${/* sc-selector */ NavText},
  ${/* sc-selector */ LogoImage},
  ${/* sc-selector */ CoBrandingImage},
  ${/* sc-selector */ InPartnership},
  ${/* sc-selector */ InfoContainer} {
    ${({isOpen}) => isOpen && `opacity: 0;`}
  }

  ${/* sc-selector */ ShortLogoImage},
  ${/* sc-selector */ ShortCoBrandingImage} {
    ${({isOpen}) => !isOpen && `display: none;`}
  }

  ${responsive.laptop} {
    flex-flow: row nowrap;
    height: ${headerHeightLaptop}px;
    right: 0;
    width: 100%;
    z-index: 100;

    ${/* sc-selector */ NavItemContainer} {
      width: 100%;
    }

    > ${/* sc-selector */ NavContent} {
      padding-left: 20px;
    }

    ${/* sc-selector */ NavText},
    ${/* sc-selector */ LogoImage},
    ${/* sc-selector */ CoBrandingImage},
    ${/* sc-selector */ InPartnership},
    ${/* sc-selector */ InfoContainer} {
      opacity: 1;
    }

    ${/* sc-selector */ Menu} {
      transform: translateX(${({isOpen}) => (isOpen ? 0 : 100)}%);
    }
  }

  ${responsive.mobileL} {
    height: ${headerHeightMobile}px;
  }
`;

export const SectionTitle = styled(P)`
  color: ${({theme}) => theme.white};
  font-size: 13px;
  letter-spacing: normal;
  line-height: normal;
  margin: ${({isOpen}) => (isOpen ? "20px 8px 5px -5px" : "35px 8px 5px 18px")};
  opacity: 0.7;
  text-align: ${({isOpen}) => (isOpen ? "center" : "left")};
  transition: margin 0.2s;
  width: ${({isOpen}) => (isOpen ? "64px" : "100%")};

  ${responsive.laptop} {
    margin: 20px 8px 5px 18px;
    text-align: left;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  align-items: stretch;
  display: grid;
  grid-template-areas: "nav content";
  grid-template-columns: ${navWidthHidden}px minmax(0, 1fr);
  grid-template-rows: auto;
  height: 100%;
  width: 100%;

  ${responsive.laptop} {
    grid-template-areas: "nav" "content";
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: ${headerHeightLaptop}px minmax(0, 1fr);
  }

  ${responsive.mobileL} {
    grid-template-rows: ${headerHeightMobile}px minmax(0, 1fr);
  }
`;
