import styled from "styled-components";
import {bool, oneOf} from "prop-types";
import {withAlign, textAlignProperty} from "../../helpers";
import responsive from "../../../utils/responsive";

const Heading = styled(withAlign)`
  color: ${({theme}) => theme.textDark};
  font-size: ${({primary}) => (primary ? "28" : "24")}px;
  line-height: ${({primary}) => (primary ? "1.29" : "1.17")};
  ${responsive.mobileL} {
    font-size: ${({primary}) => (primary ? "19" : "18")}px;
  }
`;

Heading.displayName = "Heading";

Heading.propTypes = {
  primary: bool,
  align: oneOf(textAlignProperty),
};

Heading.defaultProps = {
  primary: false,
  align: "left",
};

export default Heading;
