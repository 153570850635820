import styled from "styled-components";
import {oneOf} from "prop-types";
import styledText from "../styledText";
import textAlignProperty from "../textAlignProperty";

const withAlign = styled(styledText)`
  text-align: ${({align}) => align};
`;

withAlign.displayName = "withAlign";

withAlign.propTypes = {
  align: oneOf(textAlignProperty),
};

withAlign.defaultProps = {
  align: "left",
};

export default withAlign;
