import styled from "styled-components";

import getSize from "../utils/avatar";
import responsive from "../utils/responsive";

const getColors = (theme, type) => {
  const types = {
    dark: {
      bg: theme.navigationPrimaryHQDark,
      border: theme.navigationPrimaryHQDark,
      text: theme.white,
    },
    default: {
      bg: theme.primaryLight,
      border: theme.primaryLight,
      text: theme.white,
    },
    light: {
      bg: "transparent",
      border: theme.divider04,
      text: theme.text,
    },
    primary: {
      bg: theme.primary,
      border: theme.primary,
      text: theme.white,
    },
    white: {
      bg: theme.white,
      border: theme.white,
      text: theme.primary,
    },
  };

  return types[type] || types.default;
};

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({theme, type}) => getColors(theme, type).bg};
  background-image: ${({imageUrl}) => (imageUrl ? `url(${imageUrl})` : "none")};
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  border: ${({theme, type, imageUrl}) =>
    imageUrl ? "none" : `1px solid ${getColors(theme, type).border}`};
  color: ${({theme, type}) => getColors(theme, type).text};
  display: flex;
  flex-shrink: 0;
  font-size: ${({size}) => getSize(size, [36, 26, 20, 20, 12])}px;
  height: ${({size}) => getSize(size, [105, 75, 60, 50, 35])}px;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
  width: ${({size}) => getSize(size, [105, 75, 60, 50, 35])}px;

  ${responsive.mobileL} {
    font-size: ${({size}) => getSize(size, [20, 12, 12])}px;
    height: ${({size}) => getSize(size, [50, 35, 35])}px;
    width: ${({size}) => getSize(size, [50, 35, 35])}px;
  }
`;
