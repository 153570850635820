import styled, {css, keyframes} from "styled-components";

const autofill = ({theme}) => keyframes`
  to {
      color: ${theme.textDark};
      background: transparent;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin-top: ${({mt}) => mt}px;
  margin-bottom: ${({mb}) => mb}px;
`;

const fieldStyles = css`
  background: none;
  background-color: ${({bgColor}) => bgColor || "none"};
  color: ${({theme}) => theme.textDark};
  font-size: 16px;
  padding: 12px 10px 10px 0;
  padding-left: ${({beforeContent}) => (beforeContent ? 18 : 0)}px;
  padding-right: ${({afterIcon}) => (afterIcon ? 22 : 10)}px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid ${({theme}) => theme.primaryLighten};
  font-family: ${({theme}) => theme.font};
  outline: none;

  &::placeholder {
    color: ${({theme}) => theme.textLight};
  }

  &::-moz-placeholder {
    opacity: 1;
  }

  &:focus ~ label,
  &:valid ~ label {
    top: ${({labelPT}) => labelPT}px;
    font-size: 13px;
  }
  &:valid {
    border-bottom: 2px solid ${({theme, borderValidColor}) => borderValidColor || theme.textLight};
  }
  &:focus ~ label {
    top: ${({labelPT}) => labelPT}px;
    font-size: 13px;
    color: ${({theme, error}) => (error ? theme.error : theme.primary)};
  }
  &:focus ~ span::before {
    width: 100%;
  }

  &:focus ~ div[data-select="prefix"],
  &:valid ~ div[data-select="prefix"],
  &:focus ~ div[data-select="suffix"],
  &:valid ~ div[data-select="suffix"] {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s ease;
  }

  ${({error, theme}) =>
    error &&
    `
    & ~ span:before {
      width: 100%;
      background-color: ${theme.error};
  }
  `}

  /* Removing input background colour for Chrome autocomplete */
  &:-webkit-autofill {
    animation-name: ${autofill};
    animation-fill-mode: both;
  }
`;

export const Input = styled.input`
  ${fieldStyles};
`;

export const Textarea = styled.textarea`
  ${fieldStyles};

  &:focus ~ label,
  &:valid ~ label {
    top: ${({labelPT}) => labelPT}px;
  }
  & ~ label {
    top: ${({labelPT}) => labelPT}px;
  }

  padding-top: 5px;
  resize: none;
`;

export const Label = styled.label`
  color: ${({theme, error}) => (error ? theme.error : theme.textAccent)};
  font-size: 16px;
  font-weight: 400;
  font-family: ${({theme}) => theme.font};
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 15px;
  transition: all 300ms ease;
  ${({sticky, labelPT}) =>
    sticky &&
    `
    top: ${labelPT}px;
    font-size: 13px;
  `};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  right: 0;
  top: ${({labelPT}) => labelPT}px;
  align-items: center;
`;

export const Bar = styled.span`
  position: relative;
  display: block;
  width: 100%;

  &::before {
    content: "";
    height: 2.1px;
    width: 0;
    bottom: 0;
    position: absolute;
    background-color: ${({theme}) => theme.primary};
    transition: width 0.3s ease, background-color 0.3s;
    will-change: width;
    left: 0%;
  }
`;

export const Error = styled.span`
  position: absolute;
  color: ${({theme}) => theme.error};
  font-size: 13px;
  top: 100%;
  margin-top: 3px;
`;

export const BeforeWrapper = styled.div.attrs(() => ({
  "data-select": "prefix",
}))`
  visibility: ${({sticky}) => (sticky ? "visible" : "hidden")};
  opacity: ${({sticky}) => (sticky ? 1 : 0)};
  position: absolute;
  top: 0;
  height: 100%;
  min-width: 12px;
  max-height: 43px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  color: ${({theme}) => theme.textAccent};
  line-height: 1;
  i {
    margin-left: -4px;
  }
`;

export const AfterWrapper = styled.div.attrs(() => ({
  "data-select": "suffix",
}))`
  align-items: center;
  color: ${({theme}) => theme.textAccent};
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: 1;
  max-height: 43px;
  min-width: 12px;
  padding: 12px 0;
  cursor: ${({isClickable}) => (isClickable ? "pointer" : "none")};
  pointer-events: ${({isClickable}) => (isClickable ? "visible" : "none")};
  position: absolute;
  right: 0;
  top: 0;
`;
