import styled from "styled-components";

export const Circle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  color: ${({theme}) => theme.textAccent};
  transition: color 0.2s;
  flex-shrink: 0;
  ${({small}) => small && `transform: scale(0.8);`}
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: currentColor;
    transition: transform 0.2s ease-in-out;
    &:first-child {
      transform: scale(0);
    }
  }
`;

export const Input = styled.input.attrs(({value}) => ({
  type: "radio",
  id: value,
}))`
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;

  &:checked ~ ${Circle} {
    color: ${({theme}) => theme.primary};
    svg:first-child {
      transform: none;
    }
  }
`;
