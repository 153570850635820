import React from "react";
import {oneOf, string} from "prop-types";
import {LoadWrap, Svg, Circle} from "./Loader.style";

const Loader = ({size, color}) => (
  <LoadWrap size={size} color={color}>
    <Svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <Circle
        fill="none"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </Svg>
  </LoadWrap>
);

Loader.propTypes = {
  color: string,
  size: oneOf(["big", "medium", "small"]),
};

Loader.defaultProps = {
  size: "big",
};

export default Loader;
