import styled from "styled-components";

import UICard from "../Card";
import {Heading} from "../Text";
import UIIcon from "../Icon";
import {Column, Row} from "../Flex";
import responsive from "../utils/responsive";
import Button from "../Button";

export const Card = styled(UICard)`
  padding: 40px;
  margin: auto;
  max-width: 652px;
  align-items: center;
  ${responsive.mobileL} {
    padding: 20px;
  }
`;

export const CardContent = styled(Column)`
  align-items: center;
  justify-content: center;
  padding: 10% 0;
  width: 100%;
`;

export const CardFooter = styled(Row)`
  border-top: 1px solid ${({theme}) => theme.divider04};
  justify-content: center;
  padding-top: 30px;
  width: 100%;
  ${responsive.mobileL} {
    padding-top: 20px;
  }
`;

export const Title = styled(Heading).attrs(() => ({
  primary: true,
  align: "center",
}))`
  &:not(:last-child) {
    margin-bottom: 6px;
    ${responsive.mobileL} {
      margin-bottom: 4px;
    }
  }
`;

export const ActionButton = styled(Button).attrs(() => ({
  narrow: true,
  icon: "plus",
}))``;

export const Icon = styled(UIIcon)`
  font-size: 32px;
  color: ${({theme}) => theme.textAccent};
  &:not(:only-child) {
    margin-bottom: 23px;
    ${responsive.mobileL} {
      margin-bottom: 16px;
    }
  }
`;
