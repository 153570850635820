import styled from "styled-components";

import Toaster from "../Toaster";

export const Container = styled(Toaster.Item)`
  p {
    font-size: 14px;
    font-weight: normal;
  }

  padding: 0;
`;
