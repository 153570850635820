import React, {useRef} from "react";
import {string, func, bool, arrayOf, shape, oneOf} from "prop-types";

import {List, Item, Label} from "./Popover.style";
import useClickOutside from "../utils/Hooks/useClickOutside";

export const Content = ({
  onClickOutside,
  items,
  alignment,
  isVisible,
  align,
  className,
  stopEventOnClickOutside,
}) => {
  const wrapperRef = useRef(null);

  useClickOutside(
    wrapperRef,
    () => {
      if (isVisible) {
        onClickOutside();
      }
    },
    {stopEventOnClickOutside},
  );

  return (
    <List ref={wrapperRef} alignment={alignment} visible={isVisible} className={className}>
      {items.map(item => (
        <Item key={item.id} onClick={item.onClick} data-testid={item.testID}>
          <Label align={align}>{item.label}</Label>
        </Item>
      ))}
    </List>
  );
};

Content.propTypes = {
  onClickOutside: func.isRequired,
  isVisible: bool,
  className: string,
  items: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      onClick: func.isRequired,
      testID: string,
    }).isRequired,
  ).isRequired,
  alignment: oneOf(["left", "right", "top", "bottom"]),
  align: oneOf(["left", "right", "center"]),
  stopEventOnClickOutside: bool,
};

Content.defaultProps = {
  alignment: "left",
  align: "center",
  isVisible: false,
  stopEventOnClickOutside: false,
};

const Popover = ({isVisible, ...props}) =>
  isVisible && <Content {...props} isVisible={isVisible} />;

Popover.propTypes = {
  isVisible: bool,
  onClickOutside: func.isRequired,
  items: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      onClick: func.isRequired,
      testID: string,
    }).isRequired,
  ).isRequired,
  alignment: oneOf(["left", "right", "top", "bottom"]),
};

Popover.defaultProps = {
  isVisible: false,
};

export default Popover;
