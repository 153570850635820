const doc = document;
const div = doc.createElement("div");
div.style.overflowY = "scroll";
div.style.width = "50px";
div.style.height = "50px";
div.style.visibility = "hidden";
doc.body.appendChild(div);
const scrollWidth = div.offsetWidth - div.clientWidth;
doc.body.removeChild(div);

const body = doc.getElementsByTagName("body")[0];
let locked = false;
const bodyClassList = body.classList;

export const lockScroll = (width = null) => {
  const hasWidthDefined = width ? window.matchMedia(`(max-width: ${width}px)`).matches : true;
  if (!locked && hasWidthDefined) {
    if (body.offsetHeight < body.scrollHeight) {
      body.style.paddingRight = `${scrollWidth}px`;
    }
    bodyClassList.add("scroll-locked");
    locked = true;
  }
};

export const unlockScroll = () => {
  if (locked) {
    bodyClassList.remove("scroll-locked");
    body.style.paddingRight = "";
    locked = false;
  }
};
