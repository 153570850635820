import React from "react";
import {string, shape, bool, arrayOf, func, number} from "prop-types";

import Radio from "../Radio";
import {Wrapper} from "./RadioGroup.style";

const RadioGroup = ({options, horizontal, input, meta, small, mt, mb, style, className}) => (
  <>
    <Wrapper horizontal={horizontal} mt={mt} mb={mb} style={style} className={className}>
      {options.map(({value, label, disabled, testID}) => (
        <Radio
          key={value}
          label={label}
          testID={testID}
          disabled={disabled}
          small={small}
          input={{
            ...input,
            value,
            checked: value === input.value,
          }}
        />
      ))}
    </Wrapper>
    {meta.touched && meta.error && <div>{meta.error}</div>}
  </>
);

RadioGroup.propTypes = {
  horizontal: bool,
  meta: shape({
    touched: bool.isRequired,
    error: string,
  }).isRequired,
  options: arrayOf(
    shape({
      label: string,
      disabled: bool,
      value: string.isRequired,
    }).isRequired,
  ),
  input: shape({
    value: string,
    name: string.isRequired,
    checked: bool.isRequired,
    onChange: func.isRequired,
  }).isRequired,
  small: bool,
  mt: number,
  mb: number,
  style: shape({}),
  className: string,
};

RadioGroup.defaultProps = {
  horizontal: false,
  small: false,
  mt: 0,
  mb: 0,
  style: {},
  className: "",
};

export default RadioGroup;
