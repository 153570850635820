import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {bool, func, node, number, oneOfType, string} from "prop-types";

import {Heading} from "../../Text";
import Flex from "../../Flex";
import {
  ItemTitleRow,
  ItemButton,
  ItemContent,
  ItemFooter,
  ItemIcon,
  ItemTitle,
  ItemView,
  TopImage,
} from "../RPAccordion.style";
import {ItemContext, GlobalContext} from "./Context";

export const Item = ({
  beforeTitle,
  children,
  creamBackground,
  expendItemTestId,
  footer,
  info,
  onToggle,
  subTitle,
  title,
  titleActionIcon,
  titleComponent,
  titlePb,
  topImage,
  withTitleBorder,
  clickable,
  testID,
}) => {
  const itemRef = useRef(null);
  const [isOpened, setOpened] = useState(false);
  const {isAllOpened} = useContext(GlobalContext);
  const toggleOpen = useCallback(
    () => {
      if (!clickable) {
        return;
      }

      setOpened(!isOpened);

      if (onToggle) {
        onToggle(!isOpened);
      }
    },
    [isOpened, clickable, onToggle],
  );

  useEffect(() => setOpened(isAllOpened), [isAllOpened]);

  useEffect(
    () => {
      const scrollToRef = () => {
        if (isAllOpened) {
          return;
        }
        if (isOpened) {
          const {top} = itemRef.current.getBoundingClientRect();
          window.scrollTo({
            behavior: "smooth",
            top: window.pageYOffset + top - 120,
          });
        }
      };
      const timer = setTimeout(scrollToRef, 170);

      return () => clearTimeout(timer);
    },
    [isAllOpened, isOpened],
  );

  const shouldShowTitle = title || titleComponent || titleActionIcon || info;

  return (
    <ItemContext.Provider value={{isOpened, toggleOpen}}>
      {topImage && <TopImage>{topImage}</TopImage>}
      <ItemView creamBackground={creamBackground} ref={itemRef} data-testid={testID}>
        <ItemButton onClick={toggleOpen} clickable={clickable}>
          {clickable && (
            <ItemIcon name={isOpened ? "minus" : "plus"} data-testid={expendItemTestId} />
          )}
        </ItemButton>
        <ItemContent>
          {beforeTitle && beforeTitle}
          {shouldShowTitle && (
            <ItemTitle
              data-testid={expendItemTestId}
              isOpened={isOpened}
              onClick={toggleOpen}
              titlePb={titlePb}
              withTitleBorder={withTitleBorder}
              clickable={clickable}
            >
              <Flex.Column>
                <ItemTitleRow>
                  {titleComponent || <Heading>{title}</Heading>}
                  {titleActionIcon && titleActionIcon}
                </ItemTitleRow>
                {subTitle}
              </Flex.Column>
              {info && info}
            </ItemTitle>
          )}
          {children}
          {footer && <ItemFooter>{footer}</ItemFooter>}
        </ItemContent>
      </ItemView>
    </ItemContext.Provider>
  );
};

Item.propTypes = {
  subTitle: oneOfType([node, string]),
  beforeTitle: node,
  children: node,
  creamBackground: bool,
  expendItemTestId: string,
  testID: string,
  footer: node,
  info: node,
  onToggle: func,
  title: string,
  titleActionIcon: node,
  titleComponent: node,
  titlePb: number,
  topImage: node,
  withTitleBorder: bool,
  clickable: bool,
};

Item.defaultProps = {
  subTitle: null,
  beforeTitle: null,
  children: null,
  creamBackground: false,
  footer: null,
  info: null,
  title: "",
  titleActionIcon: null,
  titlePb: 18,
  topImage: null,
  withTitleBorder: true,
  clickable: true,
  testID: string,
};
